import React from "react";
import { Grid, ButtonGroup, Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "./assets/Card/Card.js";
import CardBody from "./assets/Card/CardBody.js";
import GridItem from "./assets/Grid/GridItem.js";
import EditForm from "./EditForm";
import STLForm from "./STLForm";
import { CircularProgress, Backdrop } from "@material-ui/core";
import "./Timesheet.css";

const styles = {
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
};

class ListForm extends React.Component {
  constructor() {
    super();
    this.state = {
      field: "",
      list: [],
      content: "",
      isStl: false,
      options: {},
      employees: [],
      invoices: [],
      empCode: "",
      activeButton: "btn4", // Active Tab Button State
      is_loading: true,
      user: {},
    };

    this.fetchList = this.fetchList.bind(this);
    this.handleAutoComplete = this.handleAutoComplete.bind(this);
    this.handleEmployee = this.handleEmployee.bind(this);
    this.setBtn1 = this.setBtn1.bind(this);
    this.setBtn2 = this.setBtn2.bind(this);
    this.setBtn3 = this.setBtn3.bind(this);
    this.setBtn4 = this.setBtn4.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
    this.fetchList("activity", "activity");
  }

  // Start- Active Tab Button functions

  setBtn1() {
    this.setState(
      {
        activeButton: "btn1",
        is_loading: true,
      },
      () => console.log("inside btn1", this.state.activeButton)
    );
  }

  setBtn2() {
    this.setState(
      {
        activeButton: "btn2",
        is_loading: true,
      },
      () => console.log("inside btn1", this.state.activeButton)
    );
  }

  setBtn3() {
    this.setState(
      {
        activeButton: "btn3",
        is_loading: true,
      },
      () => console.log("inside btn1", this.state.activeButton)
    );
  }

  setBtn4() {
    this.setState(
      {
        activeButton: "btn4",
        is_loading: true,
      },
      () => console.log("inside btn1", this.state.activeButton)
    );
  }
  // End- Active Tab Button functions

  // fetch user info
  fetchUser() {
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      // console.log(data)
      // if(data.hasOwnProperty("detail") && data["detail"].toLowerCase().includes("expired"))
      //     window.location = process.env.REACT_APP_TIMESHEET_INDEX_URL
      this.setState({ user: data });
    });
  }

  // fetch first dropdown details
  async fetchData(field) {
    var url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/${field}-name/`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  // fetch STL tab all dropdown options
  async fetchSTLOptions() {
    var url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/stl-name/`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  // fetch invoices on employee select in activity tab
  async fetchInvoices(empID) {
    var url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/activity-invoice-name/${empID}/`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }

  // function to run on tab click
  async fetchList(field, page) {
    var data = await this.fetchData(field);
    // console.log(data)

    if (field === "activity") {
      this.setState({
        field: field,
        employees: data,
        content: "",
        isStl: false,
        is_loading: false,
      });
      return;
    }

    var stl = false;
    if (page === "stl") {
      stl = true;
      var options = await this.fetchSTLOptions();
      this.setState({
        options: options,
      });
    }
    this.setState({
      field: field,
      list: data,
      content: "",
      isStl: stl,
      is_loading: false,
    });
  }

  // componentDidUpdate() {
  //     console.log("1")
  // }

  // render forms on dropdown select/change
  handleAutoComplete(e, val, emp) {
    // console.log("CROSSCHECK", this.state.isStl)
    //console.log("CROSSCHECK e", e);
    console.log("Submit");
    this.setState({ is_loading: true }, () =>
      console.log("LOADING", this.state.is_loading)
    );
    setInterval(() => {
      this.setState({ is_loading: false });
    }, 3000);

    // console.log("CROSSCHECK activity clicked", activityClick.clicked)

    if (val === null) this.setState({ content: "" });
    else {
      var form;
      if (this.state.isStl) {
        console.log("stl area");
        form = <STLForm item={val} options={this.state.options} />;
      } else {
        console.log("Activity area", this.state.field);
        form = <EditForm isEmployee={emp} edit={this.state.field} item={val} />;
      }
      this.setState({
        content: form,
      });
    }

    // console.log("CROSSCHECK CONTENT ", this.state.content)
  }

  // fetch invoices for that employee on employee select/change
  async handleEmployee(e, val) {
    // console.log(val)
    if (val === null)
      this.setState({
        content: "",
        invoices: [],
        empCode: "",
      });
    else {
      var invs = await this.fetchInvoices(val.employee_code);
      // console.log(invs)
      this.setState({
        content: "",
        empCode: val.employee_code,
        invoices: invs,
      });
    }
  }

  render() {
    var jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("C-X-ssoJwt="));
    if (jwt === undefined) {
      window.location = process.env.REACT_APP_SSO_LOGIN_URL;
    }

    return (
      <div>
        {this.state.is_loading ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null}
        <Grid container spacing={3}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <ButtonGroup color="primary" size="large">
                    {(this.state.user.is_stl ||
                      this.state.user.is_hr ||
                      this.state.user.is_superuser) && (
                      <Button
                        variant={
                          this.state.activeButton === "btn1"
                            ? "contained"
                            : "outlined"
                        }
                        id="stl"
                        onClick={() => {
                          // console.log('clicked stl');
                          this.setBtn1();
                          this.fetchList("invoice", "stl");
                        }}
                      >
                        STL
                      </Button>
                    )}
                    {(this.state.user.is_stl ||
                      this.state.user.is_hr ||
                      this.state.user.is_superuser || this.state.user.is_tech_lead || this.state.user.is_functional_lead) && (
                      <Button
                        variant={
                          this.state.activeButton === "btn2"
                            ? "contained"
                            : "outlined"
                        }
                        id="invoice"
                        onClick={() => {
                          this.setBtn2();
                          this.fetchList("invoice", "invoice");
                        }}
                      >
                        Invoices
                      </Button>
                    )}
                    {(this.state.user.is_stl ||
                      this.state.user.is_hr ||
                      this.state.user.is_superuser || this.state.user.is_tech_lead || this.state.user.is_functional_lead) && (
                      <Button
                        variant={
                          this.state.activeButton === "btn3"
                            ? "contained"
                            : "outlined"
                        }
                        id="employee"
                        onClick={() => {
                          this.setBtn3();
                          this.fetchList("employee", "employee");
                        }}
                      >
                        Employees
                      </Button>
                    )}
                    {
                      <Button
                        variant={
                          this.state.activeButton === "btn4"
                            ? "contained"
                            : "outlined"
                        }
                        id="activity"
                        onClick={() => {
                          // console.log('clicked activity')
                          this.setBtn4();
                          this.fetchList("activity", "activity");
                        }}
                      >
                        Activity
                      </Button>
                    }
                  </ButtonGroup>
                  <br />
                  <br />
                  {this.state.field === "invoice" && (
                    <Autocomplete
                      //value="Select invoice"
                      id={this.state.field}
                      key={this.state.field}
                      options={this.state.list}
                      getOptionLabel={(option) => option.display_name}
                      style={{ width: 500 }}
                      onChange={(e, val) =>
                        this.handleAutoComplete(e, val, "invoice")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            this.state.field !== ""
                              ? this.state.field.toUpperCase()
                              : ""
                          }
                          variant="filled"
                        />
                      )}
                    />
                  )}
                  {this.state.field === "employee" && (
                    <Autocomplete
                      id={this.state.field}
                      key={this.state.field}
                      options={this.state.list}
                      getOptionLabel={(option) =>
                        `${option.display_name} - ${option.id}`
                      }
                      style={{ width: 500 }}
                      onChange={(e, val) =>
                        this.handleAutoComplete(e, val, "employee")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            this.state.field !== ""
                              ? this.state.field.toUpperCase()
                              : ""
                          }
                          variant="filled"
                        />
                      )}
                    />
                  )}
                  {this.state.field === "activity" && (
                    <div>
                      <Autocomplete
                        id="actEmployee"
                        key="actEmployee"
                        options={this.state.employees}
                        getOptionLabel={(option) =>
                          `${option.display_name} - ${option.employee_code}`
                        }
                        style={{ width: 500 }}
                        onChange={(e, val) => this.handleEmployee(e, val)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="EMPLOYEE"
                            variant="filled"
                          />
                        )}
                      />
                      <br />
                      <Autocomplete
                        id="actInvoice"
                        key={this.state.empCode}
                        disabled={this.state.invoices.length === 0}
                        options={this.state.invoices}
                        getOptionLabel={(option) => option.display_name}
                        style={{ width: 500 }}
                        onChange={(e, val) => this.handleAutoComplete(e, val)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="INVOICE"
                            variant="filled"
                          />
                        )}
                      />
                    </div>
                  )}
                  <br />
                  <div id="content">{this.state.content}</div>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>
    );
  }
}

export default ListForm;
