import { Typography } from "@material-ui/core";
import { Grid, withStyles } from "@material-ui/core";
import React from "react";
import ProfilePic from "./assets/profile_pic.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1.4),
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  icon: {
    paddingRight: "8px",
    fontSize: "33px",
  },
});

class Profile1 extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      fullName: "",
      code: null,
      holiday: "",
      location: "",
      shift: "",
      email: "",
    };
  }

  async componentDidMount() {
    var details = await this.getEmployeeDetails();
    // console.log(details);
    this.setState({
      firstName: details.first_name.toLowerCase(),
      lastName: details.last_name.toLowerCase(),
      fullName: details.full_name,
      code: details.employee_code,
      holiday: details.holiday_calendar_name,
      location: details.location,
      shift: details.shift_name,
      email: details.email,
    });
  }

  // fetch employee details
  async getEmployeeDetails() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/employee-details/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  render() {
    // check for sso cookie, if not present redirect to login
    var jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("C-X-ssoJwt="));
    if (jwt === undefined) {
      window.location = process.env.REACT_APP_SSO_LOGIN_URL;
    }

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4">Employee Profile</Typography>
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item sm={3}></Grid>
          <Grid item sm={3} xs={12}>
            <img src={ProfilePic} alt="Profile" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs>
                <Typography className={classes.root} variant="h5">
                  {this.state.fullName} - {this.state.code}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography className={classes.root}>
                  <div className={classes.iconButton}>
                    <LocationOnIcon className={classes.icon} />
                    <span style={{ fontSize: "18px" }}>
                      {this.state.location}
                    </span>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography className={classes.root} style={{ paddingTop: 0 }}>
                  <div className={classes.iconButton}>
                    <EmailIcon className={classes.icon} />
                    <span style={{ fontSize: "18px" }}>
                      {/* {`${this.state.firstName}.${this.state.lastName}@decimalpointanalytics.info`} */}
                      {this.state.email}
                    </span>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  className={classes.root}
                  style={{ fontSize: "18px" }}
                >
                  Shift: {this.state.shift}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  className={classes.root}
                  style={{ fontSize: "18px" }}
                >
                  Holiday: {this.state.holiday}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(Profile1);
