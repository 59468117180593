import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "./assets/Card/Card.js";
import CardBody from "./assets/Card/CardBody.js";
import GridItem from "./assets/Grid/GridItem.js";
import Timesheet from './Timesheet';

export default function Home() {
    const [user, setUser] = React.useState([])

    // function to get user details
    const getUserView = () => {
        fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
				"Authorization": `Bearer ${localStorage.getItem('token')}`,
			},
        })
        .then(async res => {
            var data = await res.json()
            console.log(data)
            setUser(data)
        })
    }

    var jwt = document.cookie.split('; ').find(row => row.startsWith('C-X-ssoJwt='))
    if (jwt === undefined) {
        window.location = process.env.REACT_APP_SSO_LOGIN_URL
    }

    React.useEffect(() => {        
        getUserView()
    }, [])

	return (
		<>
			<Grid container spacing={3}>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                        {
                            (user.length !== 0) &&
                            <Timesheet eid={localStorage.getItem('username')} user={user} />
                        }
                        </CardBody>
                    </Card>
            </GridItem>
          </Grid>
		</>
	);
}
