import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { format } from "date-fns";
import { Typography } from "@material-ui/core";



const SimpleMonthToggle = (props) => {
    var day = parseInt(format(props.currDate, "dd"));
    var prevMonthDate = new Date(props.currDate);
    prevMonthDate.setDate(0);
    prevMonthDate.setDate(day);

    var nextMonth = new Date(format(props.currDate, "yyyy-MM-dd"));
    var nextMonthDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, day)
  
    var showForward = true;
    var date = new Date();
    if (format(props.currDate, "yyyy-MM") === format(date, "yyyy-MM")) {
      showForward = false;
    }
    return (
        <Grid container className="strip" >
          <Grid item sm={4}>
            {
              <ArrowBackIosIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("date", format(prevMonthDate, "yyyy-MM-dd"));
                  props.changeState();
                }}
              />
            }
          </Grid>

          <Grid item sm={4}>
            <Typography>
              {format(new Date(props.currDate), "MMM yyyy")}
            </Typography>
          </Grid>
        
          <Grid item sm={2}>
            {showForward && (
              <ArrowForwardIosIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("date", format(nextMonthDate, "yyyy-MM-dd"));
                  props.changeState();
                }}
              />
            )}
          </Grid>
        
          <Grid item={2}>
            {showForward && (
              <FastForwardIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("date", format(new Date(), "yyyy-MM-dd"));
                  props.changeState();
                }}
              />
            )}
          </Grid>
        
        </Grid >
    )
}

export default SimpleMonthToggle