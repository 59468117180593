import React, { Component } from "react";
import { Button, IconButton, TableFooter } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import InfoIcon from "@material-ui/icons/InfoRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import noData from "./assets/no_data.gif";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 40,
  },
  upload: {
    marginTop: 30,
    marginBottom: 20,
  },
});

class EmployeeManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      is_error: false,
      on_submit: false,
      error_popup: false,
      dialog_status: false,
      is_loading: false,
      response_data: {
        created: 0,
        updated: 0,
      },
      error_msg: [],
      error_data: [],
    };

    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInfoDialog = this.handleInfoDialog.bind(this);
  }

  handleInfoDialog = () => {
    this.setState({
      dialog_status: true,
    });
  };

  // on file upload
  onFileUpload = (e) => {
    // console.log(e.target.files[0]);
    var formData = new FormData();
    formData.append("csv_file", e.target.files[0]);
    //Validating CSV via API
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/upload_csv/`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      let data = await response.json();
      console.log("uploaded data", data.data);

      let res_data = [];
      let errorData = [];
      let error_found = false;

      //If CSV contains invalid data/values
      if (!data["status"] && data["message"] === "Error Occured") {
        data = data.data;
        res_data = data;
        for (let i = 0; i < data.length; i++) {
          console.log("error" + [i], data[i].error);
          if (data[i].error !== "") {
            error_found = true;

            errorData.push("Error on Row: " + [i + 1] + "  " + data[i].error);
          }
        }
      }
      //If CSV file is invalid
      else if (!data["status"] && data["message"] === "Invalid csv file") {
        error_found = true;
        errorData.push(
          "Uploaded CSV Format is not correct, Please check again."
        );
      } else {
        res_data = data.data;
      }

      if (error_found) {
        this.setState({
          error_popup: true,
        });
      }
      this.setState({
        rows: res_data,
        response_data: { created: 0, updated: 0 },
        error_msg: [],
        is_error: error_found,
        error_data: errorData,
      });
    });
  };

  // handleSubmit
  handleSubmit() {
    this.setState({
      is_loading: true,
    });
    // var formData = new FormData();
    // formData.append("data_to_insert", this.state.rows);
    var dataToInsert = JSON.stringify({ data_to_insert: this.state.rows });
    console.log("Submit employee management");
    fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-managment/`, {
      method: "POST",
      body: dataToInsert,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      const data = await response.json();
      console.log("Data after", data);
      if (data !== null) {
        this.setState({
          on_submit: true,
          response_data: {
            created: data["created"],
            updated: data["updated"],
          },
          error_msg: data["error_list"],
          is_loading: false,
          rows: [],
        });
        setInterval(() => {
          this.setState({
            on_submit: false,
          });
        }, 5000);
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.is_loading ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null}
        <h2 className={classes.h2} style={{ fontFamily: "serif" }}>
          Upload CSV{" "}
        </h2>
        {/* upload button */}
        <div className={classes.upload}>
          <input
            accept=".csv"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={(event) => {
              this.onFileUpload(event);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <label htmlFor="contained-button-file">
            <Button
              startIcon={<PublishIcon />}
              variant="contained"
              color="primary"
              component="span"
            >
              Upload
            </Button>
          </label>
          <IconButton onClick={this.handleInfoDialog}>
            <InfoIcon style={{ color: "#2474fc", fontSize: "150%" }} />
          </IconButton>
          <Dialog
            open={this.state.dialog_status}
            onClose={() => {
              this.setState({
                dialog_status: false,
              });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Suggested Values for all CSV columns"}
            </DialogTitle>
            <DialogContent>
              {/* Suggested Values for CSV Table */}
              <Table
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "black",
                  marginBottom: 10,
                }}
              >
                <TableHead
                  style={{ backgroundColor: "#8ed6d7", borderBottomWidth: 2 }}
                >
                  <TableCell>Column Name</TableCell>
                  <TableCell>Description</TableCell>
                </TableHead>
                <TableRow>
                  <TableCell>Employee Code </TableCell>
                  <TableCell>Employee's Unique Code (eg. 705)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Employee's First Name (eg. FirstName)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Name </TableCell>
                  <TableCell>Employee's Last Name (eg. LastName)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email </TableCell>
                  <TableCell>
                    Employee's Email ID (eg.
                    first_name.last_name@decimalpointanalytics.com)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Role Type ID </TableCell>
                  <TableCell>Employee's Role ID (1/2)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Staff </TableCell>
                  <TableCell>Whether Employee is Staff or not (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Active </TableCell>
                  <TableCell>Whether Employee is Active or not (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is STL </TableCell>
                  <TableCell>Whether Employee is STL or not (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is HR</TableCell>
                  <TableCell>Whether Employee is HR or not (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>STL Employee Code </TableCell>
                  <TableCell>Employee's STL Employee Code (eg. 123)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reporting Manager Employee Code </TableCell>
                  <TableCell>
                    Employee's Reporting Manager Employee Code (eg. 123)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Gender </TableCell>
                  <TableCell>Employee's Gender (M/F)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date of Birth </TableCell>
                  <TableCell>
                    Employee's Date of Birth (eg. 15 March 1998)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date of Joining </TableCell>
                  <TableCell>
                    Employee's Date of Joining (eg. 15 March 1998)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Designation </TableCell>
                  <TableCell>Employee's designation (eg. Developer)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Working Date </TableCell>
                  <TableCell>
                    Employee's Last Working Date (eg. 15 March 1998)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Weeekend </TableCell>
                  <TableCell>Is Weekend (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Work Experience </TableCell>
                  <TableCell>
                    {/* Employee's Work Experience in years (eg. 2,3,4 Integer only) */}
                    Employee's Work Experience in Years and Months (eg. 1.5, 2.3, 1.0, etc.)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Highest Qualification</TableCell>
                  <TableCell>
                    Employee's Highest Qualification (eg. BCS)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Holiday Calendar</TableCell>
                  <TableCell>Employee's Holiday Calendar (eg. IN)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Billable </TableCell>
                  <TableCell>Is Billable (0/1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Premier Insitute Flag </TableCell>
                  <TableCell>
                    Whether Employee's Institute is premier or not (eg. SEMI)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start Date </TableCell>
                  <TableCell>Start Date (eg. 15 March 1998)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Shift Code </TableCell>
                  <TableCell>Employee's Shift Code (eg. 1)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Shift Name </TableCell>
                  <TableCell>Employee's Shift Name (eg. General)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Department Name ID </TableCell>
                  <TableCell>
                    Employee's Department Name ID (eg. Big Data Analytics)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Delivery Location ID </TableCell>
                  <TableCell>
                    Employee's Delivery Location ID (eg. Nashik)
                  </TableCell>
                </TableRow>
              </Table>
            </DialogContent>
          </Dialog>
        </div>

        {this.state.error_data.length > 0 && this.state.error_popup ? (
          <Alert
            variant="filled"
            onClose={() => {
              this.setState({
                error_popup: false,
              });
            }}
            style={{
              position: "fixed",
              top: 90,
              width: "50%",
              left: "25%",
              zIndex: 1,
              textAlign: "center",
            }}
            severity="error"
          >
            {this.state.error_data.map((error_data) => {
              return <div>{error_data}</div>;
            })}
          </Alert>
        ) : null}
        {this.state.on_submit ? (
          <Alert
            variant="filled"
            style={{
              position: "fixed",
              top: 90,
              width: "50%",
              left: "25%",
              zIndex: 1,
            }}
            severity="success"
          >
            Data submitted successfully!!!
          </Alert>
        ) : null}
        {/* Table content */}
        {
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              {/* Table head */}
              <TableHead>
                <TableRow style={{ backgroundColor: "#8ed6d7" }}>
                  <TableCell>Employee&nbsp;Code</TableCell>
                  <TableCell>First&nbsp;Name</TableCell>
                  <TableCell>Last&nbsp;Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role&nbsp;Type&nbsp;Id</TableCell>
                  <TableCell>Is&nbsp;Staff</TableCell>
                  <TableCell>Is&nbsp;Active</TableCell>
                  <TableCell>Is&nbsp;Stl</TableCell>
                  <TableCell>Is&nbsp;HR</TableCell>
                  <TableCell>Stl&nbsp;Employee&nbsp;Code</TableCell>
                  <TableCell>
                    Reporting&nbsp;Manager&nbsp;Employee&nbsp;Code
                  </TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Date&nbsp;of&nbsp;Birth</TableCell>
                  <TableCell>Date&nbsp;of&nbsp;Joining</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>Last&nbsp;Working&nbsp;Date</TableCell>
                  <TableCell>Is&nbsp;Weekend</TableCell>
                  <TableCell>Work&nbsp;Experience</TableCell>
                  <TableCell>Highest&nbsp;Qualification</TableCell>
                  <TableCell>Holiday&nbsp;Calendar</TableCell>
                  <TableCell>Is&nbsp;Billable</TableCell>
                  <TableCell>Premier&nbsp;Institute&nbsp;Flag</TableCell>
                  <TableCell>Start&nbsp;Date</TableCell>
                  <TableCell>Shift&nbsp;Code</TableCell>
                  <TableCell>Shift&nbsp;Name</TableCell>
                  <TableCell>Department&nbsp;Name&nbsp;Id</TableCell>
                  <TableCell>Delivery&nbsp;Location&nbsp;Id</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>

              {/* Table body */}
              <TableBody>
                {this.state.rows.length === 0 ? (
                  <TableRow>
                    <TableCell>No&nbsp;Records&nbsp;Found</TableCell>
                  </TableRow>
                ) : (
                  this.state.rows.map((row) => (
                    <TableRow key={row.employee_code}>
                      <TableCell component="th" scope="row">
                        {row.employee_code}
                      </TableCell>
                      <TableCell align="right">{row.first_name}</TableCell>
                      <TableCell align="right">{row.last_name}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.role_type_id}</TableCell>
                      <TableCell align="right">
                        {row.is_staff ? 1 : 0}
                      </TableCell>
                      <TableCell align="right">
                        {row.is_active ? 1 : 0}
                      </TableCell>
                      <TableCell align="right">{row.is_stl ? 1 : 0}</TableCell>
                      <TableCell align="right">{row.is_hr ? 1 : 0}</TableCell>
                      <TableCell align="right">
                        {row.stl_employee_code}
                      </TableCell>
                      <TableCell align="right">
                        {row.reporting_manager_employee_code}
                      </TableCell>
                      <TableCell align="right">{row.gender}</TableCell>
                      <TableCell align="right">{row.date_of_birth}</TableCell>
                      <TableCell align="right">{row.date_of_joining}</TableCell>
                      <TableCell align="right">{row.designation}</TableCell>
                      <TableCell align="right">
                        {row.last_working_date}
                      </TableCell>
                      <TableCell align="right">
                        {row.is_weekend ? 1 : 0}
                      </TableCell>
                      <TableCell align="right">{row.work_experience}</TableCell>
                      <TableCell align="right">
                        {row.highest_qualification}
                      </TableCell>
                      <TableCell align="right">
                        {row.holiday_calendar}
                      </TableCell>
                      <TableCell align="right">
                        {row.is_billable ? 1 : 0}
                      </TableCell>
                      <TableCell align="right">
                        {row.premier_institute_flag}
                      </TableCell>
                      <TableCell align="right">{row.start_date}</TableCell>
                      <TableCell align="right">{row.shift_code}</TableCell>
                      <TableCell align="right">{row.shift_name}</TableCell>
                      <TableCell align="right">
                        {row.department_name_id}
                      </TableCell>
                      <TableCell align="right">
                        {row.delivery_location_id}
                      </TableCell>
                      <TableCell align="right">{row.error}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            {/* Footer Info Table */}
            <Table>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    Row Created: {this.state.response_data["created"]}
                  </TableCell>
                  <TableCell>
                    Row Updated: {this.state.response_data["updated"]}
                  </TableCell>
                  <TableCell>
                    Error:{" "}
                    {this.state.error_msg.map((error) => {
                      return <h6>{error.slice(8, -2)}</h6>;
                    })}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }

        {/* Submit Button */}
        <Button
          onClick={this.handleSubmit}
          variant="contained"
          color="primary"
          disabled={this.state.rows.length === 0 || this.state.is_error}
          style={{
            align: "center",
            marginTop: 40,
          }}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(EmployeeManagement);
