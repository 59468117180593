import React, { useState, useEffect } from 'react';
import MaterialTable from "@material-table/core";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Grid,Box,TextField,Button,Snackbar} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import InfoIcon from "@material-ui/icons/InfoRounded";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@mui/material/Chip";
import { ButtonGroup } from "@material-ui/core";
import {KeyboardDatePicker,MuiPickersUtilsProvider} from "@material-ui/pickers";
import { ExportCsv } from '@material-table/exporters';
import {saveAs} from 'file-saver';

const styles = (theme) => ({
  datePickerRoot: {
    "& .MuiInputBase-root": {
      height: "45px",
    },
  },
});
const LocationTable = ({classes}) => {
  const [activeTab, setActiveTab] = useState("employee_status");
  const [data, setData] = useState([]);
  const [empData,setEmpData]= useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLoading,setIsLoading]= useState(false);
  const [allEmpData, setAllEmpData] = useState([]); 
  const [isLoading2,setIsLoading2]= useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateFrom2, setDateFrom2] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [dateTo2, setDateTo2] = useState(new Date());
  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const [lastRefreshTime2, setLastRefreshTime2] = useState(null);
  const [timeAgo, setTimeAgo] = useState('Now');
  const [timeAgo2, setTimeAgo2] = useState('Now');

  const handleDateFromChange = (date) => {
    setDateFrom(date);
  };
  
  const handleDate2FromChange = (date) => {
    setDateFrom2(date);
  };

  const handleDateToChange = (date) => {
    setDateTo(date);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleViewTabClick = () => {
    setActiveTab("employee_status");
  };
  const handleUploadTabClick = () => {
    setActiveTab("employee_data");
  };
  const locationOptions = [
    { title: "Mumbai" },
    { title: "Nashik" },
    { title: "GIFT City_SEZ" },
    { title: "GIFT City_WTC" },
  ];
  const columns = [
    { title: 'Attendance Status', field: 'status' },
    { title: 'Mumbai', field: 'Mumbai' },
    { title: 'Nashik', field: 'Nashik' },
    { title: 'GIFT City_SEZ', field: 'GIFT_City_SEZ' },
    { title: 'GIFT City_WTC', field: 'GIFT_City_WTC' },
  ];
  const employeeDatacolumns = [
    { title: 'Employee Code', field: 'employeeCode' },
    { title: 'Employee Name', field: 'employeeName' },
    { title: 'Timesheet Location', field: 'timesheetLocation' },
    { title: 'Current Status', field: 'currentStatus' },
    { title: 'Punch Date', field: 'punchDate' },
    { title: 'Punch In Location', field: 'punchInLocation' },
  ];
  const [dialog_status,setDialogStatus]= useState(false);
  const handleInfoDialog = () => {
   setDialogStatus(true);
  };

  const downloadCSV = (location, category, detailedData) => {
    try {
      if (!dateFrom) {
        throw new Error("Date is not defined for the filename.");
      }
      
      const currentDate = format(new Date(dateFrom), "yyyy-MM-dd");
      const rows = detailedData[location][category]?.map(employee => ({
        emp_code: employee.emp_code,
        name: employee.name,
        location: employee.location,
      })) || [];
  
      const csvContent = ["Employee Code,Employee Name,Punch Location"].join(",") + "\n"
        + rows.map(e => [e.emp_code, e.name, e.location].join(",")).join("\n");
  
      const blob = new Blob([decodeURIComponent(encodeURI(csvContent))], { type: "text/csv;charset=utf-8" });
      saveAs(blob, `${location}_${category}_${currentDate}.csv`);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setSnackbarMessage('Error occurred while downloading the CSV.');
      setSnackbarOpen(true);
    }
  };
  
 const fetchData = async () => {
  try {
    setIsLoading(true);
    const fromDateParam = dateFrom2 ? `date=${format(dateFrom2, "yyyy-MM-dd")}` : '';
    const params = [fromDateParam].filter(Boolean).join('&');
    const apiUrl = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/biometric-get-data/?${params}`;

    const response = await fetch(
      apiUrl,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok){
      const errorData = await response.json();
      const errorMsg = errorData.detail ? errorData.detail : 'Failed to fetch data';
      throw new Error(errorMsg);
    }
    const result = await response.json();
  
    const formattedData = [
      {
        status: 'Present in Office',
        Mumbai: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Mumbai", "Present in Office", result.detailed_data);
            }}
          >
            {result.location_data?.Mumbai?.["Logged In"] ?? 0}
          </a>
        ),
        Nashik: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Nashik", "Present in Office", result.detailed_data);
            }}
          >
            {result.location_data?.Nashik?.["Logged In"] ?? 0}
          </a>
        ),
        GIFT_City_SEZ: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_SEZ", "Present in Office", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_SEZ"]?.["Logged In"] ?? 0}
          </a>
        ),
        GIFT_City_WTC: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_WTC", "Present in Office", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_WTC"]?.["Logged In"] ?? 0}
          </a>
        ),
      },
      {
        status: 'Out of Office',
        Mumbai: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Mumbai", "Out of Office", result.detailed_data);
            }}
          >
            {result.location_data?.Mumbai?.["Logged Out"] ?? 0}
          </a>
        ),
        Nashik: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Nashik", "Out of Office", result.detailed_data);
            }}
          >
            {result.location_data?.Nashik?.["Logged Out"] ?? 0}
          </a>
        ),
        GIFT_City_SEZ: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_SEZ", "Out of Office", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_SEZ"]?.["Logged Out"] ?? 0}
          </a>
        ),
        GIFT_City_WTC: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_WTC", "Out of Office", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_WTC"]?.["Logged Out"] ?? 0}
          </a>
        ),
      },
      {
        status: 'Absent',
        Mumbai: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Mumbai", "Absent", result.detailed_data);
            }}
          >
            {result.location_data?.Mumbai?.["Not Logged In From Office"] ?? 0}
          </a>
        ),
        Nashik: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Nashik", "Absent", result.detailed_data);
            }}
          >
            {result.location_data?.Nashik?.["Not Logged In From Office"] ?? 0}
          </a>
        ),
        GIFT_City_SEZ: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_SEZ", "Absent", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_SEZ"]?.["Not Logged In From Office"] ?? 0}
          </a>
        ),
        GIFT_City_WTC: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_WTC", "Absent", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_WTC"]?.["Not Logged In From Office"] ?? 0}
          </a>
        ),
      },
      {
        status: 'Out on Official Duty',
        Mumbai: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Mumbai", "Out on Official Duty", result.detailed_data);
            }}
          >
            {result.location_data?.Mumbai?.["Login from Different Location"] ?? 0}
          </a>
        ),
        Nashik: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("Nashik", "Out on Official Duty", result.detailed_data);
            }}
          >
            {result.location_data?.Nashik?.["Login from Different Location"] ?? 0}
          </a>
        ),
        GIFT_City_SEZ: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_SEZ", "Out on Official Duty", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_SEZ"]?.["Login from Different Location"] ?? 0}
          </a>
        ),
        GIFT_City_WTC: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCSV("GIFT City_WTC", "Out on Official Duty", result.detailed_data);
            }}
          >
            {result.location_data?.["GIFT City_WTC"]?.["Login from Different Location"] ?? 0}
          </a>
        ),
      },
      {
        status: 'Total',
        Mumbai: result.location_data?.Mumbai?.["Total"] ?? 0,
        Nashik: result.location_data?.Nashik?.["Total"] ?? 0,
        GIFT_City_SEZ: result.location_data?.["GIFT City_SEZ"]?.["Total"] ?? 0,
        GIFT_City_WTC: result.location_data?.["GIFT City_WTC"]?.["Total"] ?? 0,
      }
      
    ];
    setData(formattedData);
    setTimeAgo('Now');
    setLastRefreshTime(new Date());
   
  } catch (error) {
    console.error('Error fetching data:', error);
    setSnackbarMessage(error.message);
    setSnackbarOpen(true);
    setData([]);
  }
  finally{
    setIsLoading(false);
  }
};

const calculateTimeAgo = (date) => {
  if (!date) return 'Not refreshed yet';
  const secondsAgo = Math.floor((new Date() - date) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);

  if (secondsAgo < 60) return `${secondsAgo} seconds ago`;
  if (minutesAgo < 60) return `${minutesAgo} minutes ago`;
  return `${hoursAgo} hours ago`;
};

useEffect(() => {
  const interval = setInterval(() => {
    setTimeAgo(calculateTimeAgo(lastRefreshTime));
  }, 30000);

  return () => clearInterval(interval);
}, [lastRefreshTime]);

const calculateTimeAgoEmpData = (date) => {
  if (!date) return 'Not refreshed yet';
  const secondsAgo = Math.floor((new Date() - date) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);

  if (secondsAgo < 60) return `${secondsAgo} seconds ago`;
  if (minutesAgo < 60) return `${minutesAgo} minutes ago`;
  return `${hoursAgo} hours ago`;
};

useEffect(() => {
  const interval = setInterval(() => {
    setTimeAgo2(calculateTimeAgoEmpData(lastRefreshTime2));
  }, 30000);

  return () => clearInterval(interval);
}, [lastRefreshTime2]);


const fetchEmpData = async () => {
  try {
    setIsLoading2(true);
      const locationParam = selectedLocations.length > 0 ? `location=${selectedLocations.map((loc) => loc.title).join(',')}` : '';
      const fromDateParam = dateFrom ? `from_date=${format(dateFrom, "yyyy-MM-dd")}` : '';
      const toDateParam = dateTo ? `to_date=${format(dateTo, "yyyy-MM-dd")}` : '';
      
      if(dateFrom && dateTo && dateTo < dateFrom){
        return;
      }
      const params = [locationParam, fromDateParam, toDateParam].filter(Boolean).join('&');
      const apiUrl = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/biometric-view-data/?${params}`;
    
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
 
    if (!response.ok){
      const errorData = await response.json();
      const errorMsg = errorData.detail ? errorData.detail : 'Failed to fetch data';
      throw new Error(errorMsg);
    }
    const result = await response.json();
    const formattedData = result.records.map(record => ({
      employeeCode: record.employeeCode,
      employeeName: record.employeeName,
      timesheetLocation: record.timesheetLocation,
      currentStatus: record.currentStatus,
      punchDate: record.punchDate,
      punchInLocation: record.punchInLocation,
    }));
    setAllEmpData(formattedData); 
    setEmpData(formattedData);
    setLastRefreshTime2(new Date());
    
  } catch (error) {
    console.error('Error fetching data:', error);
    setSnackbarMessage(error.message);
    setSnackbarOpen(true);
    setEmpData([]);
  } finally {
    setIsLoading2(false);
  }
};

useEffect(()=>{
 fetchData();
},[dateFrom2]);

useEffect(()=>{
  fetchEmpData();
},[]);

useEffect(() => {
  fetchEmpData();
}, [dateTo,dateFrom]);

useEffect(() => {
  if (selectedLocations.length > 0) {
    const selectedLocationTitles = selectedLocations.map(loc => loc.title);
    const filteredData = allEmpData.filter(record =>
      selectedLocationTitles.includes(record.timesheetLocation)
    );
    setEmpData(filteredData);
  } else {
    setEmpData(allEmpData); 
  }
}, [selectedLocations, allEmpData]);

  return (
    <div>
        <div className={classes.tabs} style={{ marginBottom: 17 }}>
        <div
          className={classes.tabs}
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup
            color="primary"
            aria-label="small outlined button group"
            size="large"
          >
            <Button
              variant={activeTab === "employee_status" ? "contained" : "outlined"}
              color="primary"
              onClick={handleViewTabClick}
            >
              Employee Attendance Overview
            </Button>
            <Button
  
              variant={activeTab === "employee_data" ? "contained" : "outlined"}
              color="primary"
              onClick={handleUploadTabClick}
            >
            Employee Attendance Info
            </Button>
          </ButtonGroup>
        </div>
      </div>
      
      {isLoading ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} 
       anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
      <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {activeTab === "employee_status" && (
        <>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item style={{  }}>
              <KeyboardDatePicker
                maxDate={dateTo2}
                margin="normal"
                inputVariant='filled'
                name="dateFrom2"
                id="date-picker-from"
                label="Select Date"
                autoOk
                format="dd/MM/yyyy"
                value={dateFrom2}
                onChange={handleDate2FromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: { height: 60 },
                }}
                style={{width: "350px",marginLeft: "10px"}}
              />
             </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
       <MaterialTable
          style={{position: 'relative', marginTop: "10px"}}
          title={
            <div style={{}} >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ whiteSpace: 'nowrap',fontWeight: "bold",fontSize: "16px"}}>Employee Attendance Overview</span>
                  <IconButton onClick={handleInfoDialog} style={{ marginLeft: '10px' }}>
                    <InfoIcon style={{ color: "#2474fc", fontSize: "100%" }} />
                  </IconButton>
                </div>
                
                    
                <div style={{ textAlign: 'right', marginTop: '10px', marginLeft: '20px', fontSize: '0.9em', color: 'gray' }}>
                  Last refreshed: {timeAgo}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', position: "absolute", right: '10px', padding: '0px 10px'}}>
                  <IconButton onClick={fetchData} color="primary">
                    <RefreshIcon />
                  </IconButton>
                  <span style={{ whiteSpace: 'nowrap' }}>Refresh Data</span>
                </div>
              </div>
              </div>

          }
          columns={columns}
          data={data}
          options={{
            filtering: false,
            paging: false,
            emptyRowsWhenPaging: false,
            search: false, 
            headerStyle: {
              backgroundColor: "#2474FC",
              color: "#FFFFFF",
            },
            rowStyle: {
              backgroundColor: "#FFFFFF",
            },
          }}
        />

  <Dialog
    open={dialog_status}
    onClose={() => setDialogStatus(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperProps={{
      style: {
        width: '600px', 
        maxWidth: '80vw',
        height: '450px', 
        maxHeight: '90vh',
      },
    }}
  >
   <DialogTitle id="alert-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Suggested Values for all Attendance Status</span>
        <IconButton
          aria-label="close"
          onClick={() => setDialogStatus(false)}
          style={{ padding: '5px' }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent
      style={{
        overflow: 'auto',
        padding: '20px',  
      }}
    >
      <Table
        style={{
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: "black",
          marginBottom: 10,
          width: '100%',
        }}
      >
        <TableHead style={{ backgroundColor: "#8ed6d7", borderBottomWidth: 2 }}>
          <TableRow>
            <TableCell>Attendance Status</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableRow>
          <TableCell>Present in Office</TableCell>
          <TableCell>Counts employees currently logged in at their designated location.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Out of Office</TableCell>
          <TableCell>Counts employees who are on a break or have logged out for the day.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Absent</TableCell>
          <TableCell>Represents employees who did not clock in or out from Office or are on Leave, indicating their absence in the Biometric Table.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Out on Official Duty</TableCell>
          <TableCell>Accounts for employees logged in from a different location than their assigned one.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Total</TableCell>
          <TableCell>The total number of employees assigned to each location as listed in the Timesheet.</TableCell>
        </TableRow>
      </Table>
    </DialogContent>
    </Dialog>
    
        </>
  )}
     {activeTab === "employee_data" && (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item style={{ marginLeft: '80px' }}>
              <KeyboardDatePicker
                maxDate={dateTo}
                margin="normal"
                inputVariant='filled'
                name="dateFrom"
                id="date-picker-from"
                label="From"
                autoOk
                format="dd/MM/yyyy"
                value={dateFrom}
                onChange={handleDateFromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: { height: 60 },
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                margin="normal"
                inputVariant='filled'
                name="dateTo"
                id="date-picker-to"
                maxDate={new Date()}
                label="To"
                autoOk
                format="dd/MM/yyyy"
                value={dateTo}
                onChange={handleDateToChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: { height: 60 },
                }}
              />
            </Grid>
            <Grid item>
              <Box width="330px">
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags"
                  options={locationOptions}
                  getOptionLabel={(option) => option.title}
                  value={selectedLocations}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="filled"
                    />
                  )}
                  onChange={(event, newValue) => {
                    const uniqueValues = newValue.filter(
                      (option, index, self) =>
                        index === self.findIndex((o) => o.title === option.title)
                    );
                    setSelectedLocations(uniqueValues);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
     )}
        {isLoading2 ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null}
    
    {activeTab === "employee_data" && (
        <MaterialTable style={{marginTop: "20px", position: 'relative'}}
          title={
            <div style={{ width: '100vw'}}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ whiteSpace: 'nowrap',fontWeight: "bold",fontSize: "16px"}}>Employee Attendance Info</span>
                </div>
                    
                <div style={{ textAlign: 'right', marginTop: '10px', marginLeft: '20px', fontSize: '0.9em', color: 'gray' }}>
                  Last refreshed: {timeAgo2}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', position: "absolute", right: '250px', padding: '0px 10px'}}>
                  <IconButton onClick={fetchEmpData} color="primary">
                    <RefreshIcon />
                  </IconButton>
                  <span style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>Refresh Data</span>
                </div>
              </div>
            </div>
        }
          columns={employeeDatacolumns}
          data={empData}
          options={{
            exportMenu: [
              {
                label: "Export CSV",
                exportFunc: (cols) => ExportCsv(cols, empData, 'EmployeeDetails_All'),
              },
            ],
            filtering: false,
            emptyRowsWhenPaging: false,
            pageSize: 20,
            pageSizeOptions: [20,50,100,200],
            headerStyle: {
              backgroundColor: "#2474FC",
              color: "#FFFFFF",
            },
            rowStyle: {
              backgroundColor: "#FFFFFF",
            },
          }}
        />
      )}
      </div>
  );
};
export default withStyles(styles)(LocationTable);