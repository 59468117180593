import React, { useState, useCallback, useEffect } from "react";

import { Button, TextField, TableFooter, Snackbar,ButtonGroup } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useHistory as useHistoryHook } from "react-router-dom";
import PaginatedEstimate from './PaginatedEstimate';

import "./ClickupData.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 20,
    marginLeft: -100,
  },
  upload: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    width: "150px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  tableCell: {
    padding: "4px 8px",
    lineHeight: "1.5",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflowing text
    textOverflow: "ellipsis", // Add ellipsis for overflowing text
  },
  errorRow: {
    backgroundColor: "#ffcccc",
  },
  createdRow: {
    backgroundColor: "rgba(0, 255, 0, 0.2)",
  },
  updatedRow: {
    backgroundColor: "#FFFFE0",
  },
});

const EstimateData = ({ classes }) => {
  const history = useHistoryHook();
  const [activeTab, setActiveTab] = useState("clickup2");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  // Error Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Snackbar for no changes
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  // Success Snackbar state
  const [snackbarsucessOpen, setSnackbarsuccessOpen] = useState(false);
  const [snackbarsuccessMessage, setSnackbarsuccessMessage] = useState("");

  const [uploading, setUploading] = useState(false); // Loader for file upload
  const [loadSubmit, setloadSubmit] = useState(false); //Loader for file submit
  const [resData, setResData] = useState({
    num_rows_inserted: 0,
    num_rows_updated: 0,
  });

  // Fetch projects and update state

  const handleUploadTabClick = () => {
    setActiveTab("clickup");
    history.push("/clickup-data");
  };

  const handleViewTabClick = () => {
    setActiveTab("clickup2");
  };

  const handleClickUpFormClick = () => {
    setActiveTab("clickupform");
    history.push("/clickup-form-data");
  };


  const preprocessRows = (rows) => {
    return rows.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (
            ["nan", "na", "none"].includes(value.toLowerCase())
          ) {
            return [key, ""];
          }
          return [key, value];
        })
      );
    });
  };
  const onSubmitFile = useCallback(
    async (event) => {
      const cleanedRows = rows;

      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/estimate-submit-data/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ cleanedRows }),
        }
      );

      const result = await response.json();
      setResData(result);
      return result;
    },
    [rows]
  );

  const handleSubmit = async () => {
    setloadSubmit(true);
    try {
      const result = await onSubmitFile();
      // Create maps for error, created, and updated rows
      const errorMap = new Map(
        result.errors.map((e) => [e.task_id, { ...e, status_task: "error" }])
      );
      const createdMap = new Map(
        result.results
          .filter((r) => r.status_task === "created")
          .map((r) => [r.task_id, { ...r, status_task: "created" }])
      );
      const updatedMap = new Map(
        result.results
          .filter((r) => r.status_task === "updated")
          .map((r) => [r.task_id, { ...r, status_task: "updated" }])
      );

      // Combine results from maps, prioritizing errors
      const combinedResults = [
        ...errorMap.values(),
        ...createdMap.values(),
        ...updatedMap.values(),
      ];

      // Update rows with combined results
      const updatedRows = rows.map((row) => {
        const updatedRow = combinedResults.find(
          (res) => res.task_id === row.task_id
        );
        if (updatedRow) {
          return { ...row, ...updatedRow };
        }
        return row;
      });

      setRows(updatedRows);
      if (!hasErrors() && result.errors.length == 0) {
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(
          "Estimate details have been uploaded successfully."
        );
        setSubmitButtonDisabled(true);
        setSelectedProjects([]);
      }
      else if (result.errors.length > 0) {
        // Show snackbar for errors
        setSnackOpen(true);
        setSnackbarText("No Changes Done");
        
      } 
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
    } finally {
      setloadSubmit(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleSuccessSnackbar = () => {
    setSnackbarsuccessOpen(false);
  };

  const handleChangeProjects = (event, newValue) => {
    setSelectedProjects(newValue);
  };

  const onFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension !== "xlsx") {
          setSnackbarOpen(true);
          setSnackbarMessage(`${file.name} format is incorrect.`);
          return;
        }
        setUploading(true);
        setRows([]);
        const formData = new FormData();
        formData.append("xlsx_file", file);

        const response = await fetch(
          `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/estimate-upload-data/`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const responseData = await response.json();

        let resData = [];
        let errorData = [];
        let errorFound = false;

        if (!responseData.status && responseData.message === "Error Occurred") {
          resData = responseData.data;
          errorFound = true;
          errorData.push("Incorrect column Sequence or date format.");
        } else if (
          !responseData.status &&
          responseData.message === "Server Error"
        ) {
          errorFound = true;
          errorData.push("Something went Wrong, Server Error");
        } else if (!responseData.status || responseData.status == 400) {
          errorFound = true;
          errorData.push(responseData.message);
        } else {
          resData = responseData && responseData.data ? responseData.data : [];
        }

        if (errorFound) {
          setSnackbarOpen(true);
          setSnackbarMessage(errorData);
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        } else {
          setSnackbarsuccessOpen(true);
          // setSnackbarsuccessMessage("File uploaded successfully.");
          setSnackbarsuccessMessage("Kindly review and Submit the Data.");
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        }
        setRows(resData);
        setUploading(false);
        setSubmitButtonDisabled(false);
      }
    },
    [selectedProjects]
  );

  const hasErrors = () => {
    return rows.some((row) => row.error && row.error.trim() !== "");
  };

  
  const handleTabAClick = () => {
    setActiveTab({ tabA: true, tabB: false, tabC: false });
  };

  const handleTabBClick = () => {
    setActiveTab({ tabA: false, tabB: true, tabC: false });
  };

  const handleTabCClick = () => {
    setActiveTab({ tabA: false, tabB: false, tabC: true });
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
         Project Estimate Data Upload
      </Typography>
      <div className={classes.tabs} style={{ marginBottom: 20, paddingBottom : '20px' }} >
        <div
          className={classes.tabs}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "7px",
          }}
        >
          <ButtonGroup
            color="primary"
            aria-label="small outlined button group"
            size="large"
           >
          <Button
            variant={activeTab === "clickup" ? "contained" : "outlined"}
            color="primary"
            onClick={handleUploadTabClick}
          >
            Clickup Data
          </Button>
          <Button
            variant={activeTab === "clickup2" ? "contained" : "outlined"}
            color="primary"
            onClick={handleViewTabClick}
          >
            Project Estimate
          </Button>
            <Button
              variant={activeTab === "clickupform" ? "contained" : "outlined"}
              color="primary"
              onClick={handleClickUpFormClick}
            >
              API Scheduler
            </Button>
            </ButtonGroup>
        </div>
      </div>
      {isLoading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      {uploading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <div className={classes.upload}>
        <input
          accept=".xlsx"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={(event) => {
            onFileUpload(event);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <label htmlFor="contained-button-file">
          <Button style={{marginTop: "-10px"}}
            startIcon={<PublishIcon />}
            variant="contained"
            color="primary"
            component="span"
          >
            Upload
          </Button>
        </label>
      </div>
      {/* Snackbar for errors */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <MuiAlert onClose={handleCloseSnack} severity="info" variant="filled">
          {snackbarText}
        </MuiAlert>
      </Snackbar>


      <PaginatedEstimate rows={rows} classes={classes} loading={loading}/>
      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: "20px" }}>
        <span className='boldText'>Rows Created: {resData["num_rows_inserted"]}</span>
        <span className='boldText'>Rows Updated: {resData["num_rows_updated"]}</span>
      </div>
      {loadSubmit ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarsucessOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbar}
      >
        <MuiAlert
          onClose={handleSuccessSnackbar}
          severity="success"
          variant="filled"
        >
          {snackbarsuccessMessage}
        </MuiAlert>
      </Snackbar>

      {/* Submit Button */}
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={rows.length === 0 || hasErrors() || isSubmitButtonDisabled}
        style={{
          align: "center",
          marginTop: 10,
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default withStyles(styles)(EstimateData);
