import React, { useState, useCallback, useEffect } from "react";

import { utils, write } from 'xlsx';
import { Button, TextField, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "@material-table/core";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useHistory as useHistoryHook } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { Grid, FormControlLabel, Checkbox,ButtonGroup } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import "./ClickupData.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 20,
    marginLeft: -100,
  },
  upload: {
    marginTop: 30,
    marginLeft: -20,
    marginBottom: 20,
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    width: "150px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  tableCell: {
    padding: "4px 8px",
    lineHeight: "1.5",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflowing text
    textOverflow: "ellipsis", // Add ellipsis for overflowing text
  },

  errorRow: {
    backgroundColor: "#ffcccc",
  },
  createdRow: {
    backgroundColor: "rgba(0, 255, 0, 0.2)",
  },
  updatedRow: {
    backgroundColor: "#FFFFE0",
  },

  datePickerRoot: {
    "& .MuiInputBase-root": {
      height: "45px",
    },
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      height: "45px",
    },
  },
  viewButton: {
    height: "45px",
    marginTop: "0 !important",
  },
});

const MarketingManagement = ({ classes }) => {
  const history = useHistoryHook();
  const [activeTab, setActiveTab] = useState("marketing_view");
  const [tables, setTable] = useState([]);
  const [showActiveRows, setShowActiveRows] = useState(false); 
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // Error Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Snackbar for no changes
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  // Success Snackbar state
  const [snackbarsucessOpen, setSnackbarsuccessOpen] = useState(false);
  const [snackbarsuccessMessage, setSnackbarsuccessMessage] = useState("");

  const [dateFrom, setDateFrom] = useState(() => {
    const day = parseInt(format(new Date(), "dd"));
    const monthAgo = new Date();
    monthAgo.setDate(0);
    monthAgo.setDate(day);
    return monthAgo;
  });
  const [dateTo, setDateTo] = useState(new Date());

  const handleDateFromChange = (date) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date) => {
    setDateTo(date);
  };

  const handleViewTabClick = () => {
    setActiveTab("marketing_view");
  };

  const handleUploadTabClick = () => {
    setActiveTab("marketing_upload");
    history.push("/marketing-data-upload");
  };

  //   const handleViewTabdemandClick = () => {
  //     setActiveTab("demand_generation");
  //     history.push("/demand-generation");
  //   };

  useEffect(() => {
    fetchTableNameData();
  }, []);

  const fetchTableNameData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-fetch-tables`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      const transformedData = data.tables.map((table, index) => ({
        id: (index + 1).toString(),
        display_name: table,
      }));
      setTable(transformedData);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };
  const fetchData = async () => {
    if (selectedTable && selectedTable.display_name && dateFrom && dateTo) {
      try {
        const selectedTableName = selectedTable.display_name;
        const queryParams = new URLSearchParams({
          table: selectedTableName,
          dateFrom: format(dateFrom, "yyyy-MM-dd"),
          dateTo: format(dateTo, "yyyy-MM-dd"),
        }).toString();

        const url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-data-view?${queryParams}`;
        
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const result = await response.json();
        setTableColumns(result.columns);
        setRows(result.data);
        setFilteredRows(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Error fetching table data. Please try again.");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedTable, dateFrom, dateTo]);

  const handleChangeTable = async (event, newValue) => {
    setSelectedTable(newValue);
    setRows([]);
    // setTableData([]);
    setFilteredRows([]);
    setTableColumns([]);

    // setIsLoading(true);
    if (newValue) {
      try {
        const selectedTableName = newValue.display_name;
        const queryParams = new URLSearchParams({
          table: selectedTableName,
          dateFrom: format(dateFrom, "yyyy-MM-dd"),
          dateTo: format(dateTo, "yyyy-MM-dd"),
        }).toString();
  
        const url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-data-view?${queryParams}`;
        
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  
        const result = await response.json();
        setTableColumns(result.columns);
        setRows(result.data);
        setFilteredRows(result.data);
        // setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setIsLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Error fetching table data. Please try again.");
      }
    }
  };

  const handleShowActiveRowsChange = (event) => {
    setShowActiveRows(event.target.checked);
  
    if (event.target.checked) {
      const activeRows = rows.filter(row => row.IS_ACTIVE === 1);
      setFilteredRows(activeRows);
    } else {
      setFilteredRows(rows);
    }
  };
  
  // const fetchTableData = async () => {
  //   if (!selectedTable || !dateFrom || !dateTo) {
  //     setSnackbarText("Please select a table and date range.");
  //     setSnackOpen(true);
  //     return;
  //   }
  //   setIsLoading(true);
  
  //   try {
  //     const queryParams = new URLSearchParams({
  //       table: selectedTable.display_name,
  //       dateFrom: format(dateFrom, "yyyy-MM-dd"),
  //       dateTo: format(dateTo, "yyyy-MM-dd"),
  //     }).toString();
  
  //     const url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-data-view?${queryParams}`;
  
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  
  //     const result = await response.json();
  //     setRows(result.data);
  //     setFilteredRows(result.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Error fetching table data. Please try again.");
  //   }
  // };

  useEffect(() => {
    if (showActiveRows) {
      const activeRows = rows.filter(row => row.IS_ACTIVE === 1);
      setFilteredRows(activeRows);
    } else {
      setFilteredRows(rows);
    }
  }, [rows, showActiveRows]);
  
  const exportToExcel = async () => {
    if (!selectedTable || !dateFrom || !dateTo) {
      setSnackbarText("Please select a table and date range.");
      setSnackOpen(true);
      return;
    }

    if (filteredRows.length === 0) {
      setSnackbarText("No data available in table to export.");
      setSnackOpen(true);
      return;
    }
  
    try {
      const queryParams = new URLSearchParams({
        table: selectedTable.display_name,
        dateFrom: format(dateFrom, "yyyy-MM-dd"),
        dateTo: format(dateTo, "yyyy-MM-dd"),
      }).toString();
      const url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/api/marketing/excel?${queryParams}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const jsonData = await response.json();
  
      if (jsonData.columns && jsonData.data) {
        // Create workbook and worksheet
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(jsonData.data, {header: jsonData.columns});
        
        // Add the worksheet to the workbook
        utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        
        // Create download link
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${selectedTable.display_name}_${format(dateFrom, "yyyy-MM-dd")}_to_${format(dateTo, "yyyy-MM-dd")}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setSnackbarOpen(true);
      setSnackbarMessage(`Error exporting to Excel: ${error.message}`);
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleSuccessSnackbar = () => {
    setSnackbarsuccessOpen(false);
  };

  

  const hasErrors = () => {
    return rows.some((row) => row.error && row.error.trim() !== "");
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
        Marketing Data Management
      </Typography>
      <div className={classes.tabs} style={{ marginBottom: 17 }}>
        <div
          className={classes.tabs}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup
            color="primary"
            aria-label="small outlined button group"
            size="large"
          >
            <Button
              // style={{ padding: "5px 10px" }}
              variant={activeTab === "marketing_view" ? "contained" : "outlined"}
              color="primary"
              onClick={handleViewTabClick}
            >
              View Data
            </Button>
            <Button
              // style={{ padding: "5px 10px" }}
              variant={activeTab === "marketing_upload" ? "contained" : "outlined"}
              color="primary"
              onClick={handleUploadTabClick}
            >
              Upload Data
            </Button>
          </ButtonGroup>
          {/* <Button
            style={{ padding: "5px 10px" }}
            variant={
              activeTab === "demand_generation" ? "contained" : "outlined"
            }
            color="primary"
            onClick={handleViewTabdemandClick}
          >
            Demand Generation
          </Button> */}
        </div>
      </div>

      <div
        className={classes.container}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={classes.container}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <KeyboardDatePicker
                  maxDate={dateTo}
                  inputVariant="filled"
                  margin="normal"
                  name="dateFrom"
                  id="date-picker-from"
                  label="From"
                  autoOk={true}
                  okLabel={""}
                  cancelLabel={""}
                  format="dd/MM/yyyy"
                  value={dateFrom}
                  onChange={handleDateFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    style: { height: 60 }
                  }}
                  className={classes.datePickerRoot}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  inputVariant="filled"
                  margin="normal"
                  name="dateTo"
                  id="date-picker-to"
                  maxDate={new Date()}
                  label="To"
                  autoOk={true}
                  okLabel={""}
                  cancelLabel={""}
                  format="dd/MM/yyyy"
                  value={dateTo}
                  onChange={handleDateToChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{
                    style: { height: 60 }
                  }}
                  className={classes.datePickerRoot}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  options={tables}
                  style={{ width: 330}}
                  noOptionsText={"No records found"}
                  getOptionLabel={(option) => option.display_name}
                  value={selectedTable}
                  onChange={handleChangeTable}
                  
                  loading={loadingTables}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Select Table"
                      InputProps={{
                        ...params.InputProps,
                        style: { height: 60, paddingTop: 15, paddingBottom: 0 }
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.display_name}
                        {...getTagProps({ index })}
                        key={option.id}
                        style={{ marginRight: 4, marginBottom: 4 }}
                      />
                    ))
                  }
                  className={classes.autocomplete}
                />
              </Grid>
              {/* <Grid item className="buttView">
                <Button
                  sx={{
                    padding: "6px 16px",
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                  }}
                  onClick={fetchTableData}
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={!selectedTable}
                  className={classes.viewButton}
                >
                  VIEW
                </Button>
              </Grid> */}
            </Grid>
          </MuiPickersUtilsProvider>

          {isLoading ? (
            <div className="backdrop">
              <div className="loader"></div>
            </div>
          ) : null}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <MuiAlert onClose={handleCloseSnack} severity="info" variant="filled">
          {snackbarText}
        </MuiAlert>
      </Snackbar>

      <Grid container alignItems="flex-start">
        <Grid item>
          <FormControlLabel
          control={
            <Checkbox
              checked={showActiveRows}
              onChange={handleShowActiveRowsChange}
              color="primary"
            />
          }
          label="Show Active"
        />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <MaterialTable
            options={{
              filtering: false,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 50, 100, 250],
              headerStyle: {
                backgroundColor: "#2474FC",
                color: "#FFFFFF",
              },
              rowStyle: {
                backgroundColor: "#FFFFFF",
              },
              exportButton: true,
              exportMenu: [{
                label: 'Export to Excel',
                exportFunc: (cols, data) => exportToExcel()
              }]
            }}
            title="View Table Data"
            columns={tableColumns.map(column => ({ title: column, field: column,
            cellStyle: column === "ABOUT_INQUIRY" 
                  ? { 
                      width: '800px', 
                      maxWidth: '800px', 
                      minWidth: '500px', 
                      whiteSpace: 'normal',  
                      wordWrap: 'break-word'
                    } 
                  : [
                    "DG_DATE", 
                    "WEB_ANALYTICS_DATE", 
                    "DM_DATE", 
                    "BA_PR_DATE", 
                    "EMAIL_DATE", 
                    "EVENT_DATE",
                    "DATE",
                    "CONTENT_DATE"
                  ].includes(column)
                ? {
                    width: '50px',
                    maxWidth: '50px',
                    minWidth: '50px'
                  }:
                  ["TITLE", "LINK"].includes(column) 
              ? {
                width: '600px', 
                maxWidth: '1000px',
                minWidth: '400px',
                whiteSpace: 'normal',  
                wordWrap: 'break-word'
              }:
          {},
            headerStyle: column === "ABOUT_INQUIRY" 
              ? { width: '800px', maxWidth: '800px', minWidth: '500px' }  
              : ["TITLE", "LINK"].includes(column) 
              ? {
                  width: '500px',
                  maxWidth: '600px',
                  minWidth: '500px',
                }:
                {},
          }))}
            data={filteredRows}
          />
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarsucessOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbar}
      >
        <MuiAlert
          onClose={handleSuccessSnackbar}
          severity="success"
          variant="filled"
        >
          {snackbarsuccessMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default withStyles(styles)(MarketingManagement);
