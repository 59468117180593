import React from "react";
import {
  Checkbox,
  withStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "white",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const MuiTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

class SingleTable extends React.Component {
  constructor() {
    super();
    this.state = {
      client_res: [],
      //dropdown options
      projects: [],
      defValue: false,

      //all rows
      rows: [],
    };

    this.onResChangeHandler = this.onResChangeHandler.bind(this);
    this.onProjectChangeHandler = this.onProjectChangeHandler.bind(this);
    this.monthToggleHandler = this.monthToggleHandler.bind(this);
    this.dropdownChangeHandler = this.dropdownChangeHandler.bind(this);
  }

  async componentDidMount() {
    this.unFreezeDates();
    var projects = this.props.rows.map((pr) => pr.project);
    projects = [...new Set(projects)];
    var element = document.getElementById("todayHeader");
    // var table = document.getElementById("timesheet-table")
    let client_res = await this.getClientResponse();
    const new_client_res = client_res.map(
      ({ employee_code, ...items }) => items
    );
    this.setState({
      client_res: new_client_res,
      projects: projects,
      rows: this.props.rows,
    });
    // console.log("projects", projects);
    if (element !== null)
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }

  componentDidUpdate() {
    this.unFreezeDates();
    var element = document.getElementById("todayHeader");
    // var table = document.getElementById("timesheet-table")
    
    // TO AVOID SCROLL UP ON SUBMIT
    // if (element !== null)
    //   element.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
  }

  monthToggleHandler(newState) {
    this.setState({
      rows: newState,
    });
  }

  dropdownChangeHandler() {
    this.setState({
      defValue: !this.state.defValue,
    });
  }

  // project filter
  onProjectChangeHandler(e, value) {
    if (value) {
      let filteredProjects = this.props.rows.filter((item) => {
        return item.project.includes(value);
      });

      this.setState({
        rows: filteredProjects,
      });
    } else {
      this.setState({
        rows: this.props.rows,
      });
    }
  }

  //handling status of response
  onResChangeHandler(e) {
    let my_arr = e.target.value.split(" ");

    if (my_arr[0] === "true") {
      my_arr[0] = true;
    } else {
      my_arr[0] = false;
    }

    let newResponse = {
      is_responded: my_arr[0],
      date: my_arr[1],
    };
    // Map over old data and return new objects so we dont mutate state
    let count = 0;
    let newData = this.state.client_res.map((obj) => {
      // If the date matches then update the value
      if (obj.date == newResponse.date) {
        count += 1;
        return { ...obj, is_responded: newResponse.is_responded };
      }
      // else return the same object
      return obj;
    });

    // Update the state with the new object
    if (count > 0) {
      this.setState({ client_res: newData }, () => {
        this.props.onSubmitClientRes(this.state.client_res);
      });
    } else {
      this.setState(
        (prevState) => ({
          client_res: [...prevState.client_res, newResponse],
        }),
        () => {
          this.props.onSubmitClientRes(this.state.client_res);
        }
      );
    }
  }

  // unfreeze(disabled = false) rejected dates to re-fill
  unFreezeDates() {
    var dates = Object.keys(this.props.rejectedDates);
    // console.log("rejected dates: ", dates);
    for (let i = 0; i < dates.length; i++) {
      var hourCells = document.querySelectorAll(`[data-date="${dates[i]}"]`);
      // console.log("rejected hour cells: ", hourCells.length);
      for (let j = 0; j < hourCells.length; j++) {
        hourCells[j].disabled = false;
        hourCells[j].style.color = "#212121";
      }
    }
  }

  //client response
  async getClientResponse() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/client-response/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  render() {
    var dateHours = [];
    var keys = Object.keys(this.props.totalDateHours);
    var join_index = keys.indexOf(this.props.joiningDate);
    for (let i = 0; i < keys.length; i++) {
      if (i < join_index) {
        // setting ttl hours value to 0 before joining date
        let x = 0;
        dateHours.push(
          <TableCell style={{ textAlign: "center" }}>{x.toFixed(2)}</TableCell>
        );
      } else {
        dateHours.push(
          <TableCell style={{ textAlign: "center" }}>
            {this.props.totalDateHours[keys[i]].toFixed(2)}
          </TableCell>
        );
      }
    }

    // var checkBoxes = [];
    // for (let i = 0; i < keys.length; i++) {
    //   checkBoxes.push(
    //     <TableCell>
    //       <Checkbox name="client_query" color="primary" />
    //     </TableCell>
    //   );
    // }

    var checkBoxes = [];
    for (let i = 0; i < keys.length; i++) {
      var filtered = this.state.client_res.filter(
        (res) => keys[i] === res.date
      );
      let key_status = {};
      if (filtered.length > 0) {
        key_status = !filtered[0].is_responded + " " + filtered[0].date;

        checkBoxes.push(
          <TableCell>
            <Checkbox
              disabled={
                keys[i] >=
                  this.props.clientQueryDisabledDates["formatLastMonday"] &&
                keys[i] <= this.props.clientQueryDisabledDates["formatToday"]
                  ? false
                  : true
              }
              value={key_status}
              checked={filtered[0].is_responded ? true : false}
              name="client_query"
              color="primary"
              onChange={this.onResChangeHandler}
            />
          </TableCell>
        );
      } else {
        key_status = true + " " + keys[i];
        checkBoxes.push(
          <TableCell>
            <Checkbox
              disabled={
                keys[i] >=
                  this.props.clientQueryDisabledDates["formatLastMonday"] &&
                keys[i] <= this.props.clientQueryDisabledDates["formatToday"]
                  ? false
                  : true
              }
              value={key_status}
              checked={false}
              name="client_query"
              color="primary"
              onChange={this.onResChangeHandler}
            />
          </TableCell>
        );
      }
    }

    return (
      <Paper style={{ width: "100%" }}>
        <TableContainer style={{ maxHeight: 2300 }}>
          <Table stickyHeader id="timesheet-table">
            <TableHead>
              <TableRow>
                <StickyTableCell>
                  <MuiTableCell style={{ minWidth: "10ch" }}>
                    Sr. No.
                  </MuiTableCell>
                  <MuiTableCell style={{ minWidth: "20ch" }}>
                    Client
                  </MuiTableCell>
                  {/* <MuiTableCell style={{width: "120px"}}>BU</MuiTableCell> */}
                  <MuiTableCell style={{ minWidth: "20ch" }}>
                    {/* Project */}
                    <Autocomplete
                      key={this.state.defValue}
                      id="Project"
                      options={this.state.projects}
                      getOptionLabel={(project) => project}
                      defaultValue={[]}
                      onChange={(e, value) =>
                        this.onProjectChangeHandler(e, value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Project" />
                      )}
                    />
                  </MuiTableCell>
                  <MuiTableCell style={{ minWidth: "20ch" }}>
                    Activity
                  </MuiTableCell>
                  {/* <MuiTableCell style={{minWidth: "10ch"}}>Hrs</MuiTableCell> */}
                  {/* <MuiTableCell style={{width: "100px"}}>Approval Status</MuiTableCell> */}
                </StickyTableCell>
                {this.props.daysHeader}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map((row, index) => {
                let bg_color = 'white'
                if (row.isRemoved == true) { 
                  bg_color = '#ededed'
                }
                return (
                  <TableRow key={row.id}>
                    <StickyTableCell style={{backgroundColor:bg_color}}>
                      <MuiTableCell style={{ minWidth: "10ch" }}>
                        {index + 1}
                      </MuiTableCell>
                      <MuiTableCell style={{ minWidth: "20ch" }}>
                        {row.client}
                      </MuiTableCell>
                      {/* <MuiTableCell style={{width: "120px"}}>{row.bu}</MuiTableCell> */}
                      <MuiTableCell style={{ minWidth: "20ch" }}>
                        {row.project}
                      </MuiTableCell>
                      <MuiTableCell style={{ minWidth: "20ch" }}>
                        {row.activity}
                      </MuiTableCell>
                      {/* <MuiTableCell style={{minWidth: "10ch"}}>{row.total}</MuiTableCell> */}
                      {/* <MuiTableCell style={{width: "100px"}}>{row.approval}</MuiTableCell> */}
                    </StickyTableCell>
                    {row.days}
                  </TableRow>
                );
              })}
              <TableRow style={{ height: "10ch" }}>
                <StickyTableCell>
                  <MuiTableCell style={{ minWidth: "10ch" }}></MuiTableCell>
                  <MuiTableCell style={{ minWidth: "20ch" }}></MuiTableCell>
                  {/* <MuiTableCell style={{width: "120px"}}></MuiTableCell> */}
                  <MuiTableCell style={{ minWidth: "20ch" }}></MuiTableCell>
                  <MuiTableCell style={{ minWidth: "20ch" }}>
                    Total Hours
                  </MuiTableCell>
                  {/* <MuiTableCell style={{minWidth: "10ch"}}>{globalTotal}</MuiTableCell> */}
                  {/* <MuiTableCell style={{width: "100px"}}></MuiTableCell> */}
                </StickyTableCell>
                {dateHours}
              </TableRow>
              <TableRow>
                <StickyTableCell>
                  <MuiTableCell style={{ minWidth: "10ch" }}></MuiTableCell>
                  <MuiTableCell>
                    Have I responded to all queries sent by corresponding
                    clients?
                  </MuiTableCell>
                </StickyTableCell>
                {checkBoxes}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default SingleTable;
