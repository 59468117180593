import React from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { format } from "date-fns";
import { Typography } from "@material-ui/core";

class MonthToggle extends React.Component {
  render() {
    var day = parseInt(format(this.props.currDate, "dd"));

    var prevMonthDate = new Date(this.props.currDate);
    prevMonthDate.setDate(0);
    prevMonthDate.setDate(day);

    var nextMonth = new Date(format(this.props.currDate, "yyyy-MM-dd"));
    var nextMonthDate = new Date(
      nextMonth.getFullYear(),
      nextMonth.getMonth() + 1,
      day
    );

    var showForward = true;
    var date = new Date();

    if (format(this.props.currDate, "yyyy-MM") === format(date, "yyyy-MM"))
      showForward = false;

    return (
      <Grid container className="strip">
        <Grid item sm={4}>
          {
            <ArrowBackIosIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem(
                  "date",
                  format(prevMonthDate, "yyyy-MM-dd")
                );
                this.props.changeState();
                this.props.setData(prevMonthDate, this.props.code);
                this.props.onMonthToggle();
              }}
            />
          }
        </Grid>
        <Grid item sm={4}>
          <Typography>
            {format(new Date(this.props.currDate), "MMM yyyy")}
          </Typography>
        </Grid>
        <Grid item sm={2}>
          {showForward && (
            <ArrowForwardIosIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem(
                  "date",
                  format(nextMonthDate, "yyyy-MM-dd")
                );
                this.props.changeState();

                this.props.setData(nextMonthDate, this.props.code);
                this.props.onMonthToggle();
              }}
            />
          )}
        </Grid>
        <Grid item={2}>
          {showForward && (
            <FastForwardIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem("date", format(new Date(), "yyyy-MM-dd"));
                this.props.changeState();

                this.props.setData(new Date(), this.props.code);
                this.props.onMonthToggle();
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default MonthToggle;
