import React from "react";
import { Redirect } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newToken: "",
    };

    // this.renderLoginForm = this.renderLoginForm.bind(this);
  }

  componentDidMount() {
    // check for sso cookie, if not present redirect to login
    var jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("C-X-ssoJwt="));
    if (jwt === undefined) {
      window.location = process.env.REACT_APP_SSO_LOGIN_URL;
    } else {
      var ssoJwt = jwt.split("=")[1];
      console.log("C-X-ssoJwt: ", ssoJwt);
      const formData = new FormData();
      formData.append("C-X-ssoJwt", ssoJwt);

      // if sso cookie present
      // send request to django with that cookie/token
      let res_status;
      fetch(`${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/get-token/`, {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          res_status = res.status;
          return res.json();
        })
        .then((data) => {
          console.log("res status", res_status);
          if (res_status === 200) {
            console.log("sso returned data: ", data);
            // console.log(res_status)
            // var exp = data["exp"] * 1000
            // console.log("expiry time: ", new Date(exp))
            // console.log("current time: ", new Date())
            // window.location = process.env.REACT_APP_SSO_LOGIN_URL

            localStorage.setItem("token", data["token"]);
            localStorage.setItem("username", data["employee_code"]);
            localStorage.setItem("date", format(new Date(), "yyyy-MM-dd"));
            localStorage.setItem("page", "timesheet");

            this.setState({
              newToken: data["token"],
            });
          } else {
            this.setState({
              newToken: "no token",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  // renderLoginForm() {
  //   window.location = process.env.REACT_APP_SSO_LOGIN_URL;
  //   const { classes } = this.props;
  //   localStorage.removeItem("username");
  //   localStorage.removeItem("date");
  //   localStorage.removeItem("page");

  //   return (
  //     <>
  //       <h1>Inside renderlogin</h1>
  //       <Grid container component="main" className={classes.root}>
  //         <CssBaseline />
  //         <Grid item xs={false} className={classes.image} />
  //       </Grid>
  //     </>
  //   );
  // }

  render() {
    if (this.state.newToken !== "") {
      if (this.state.newToken === "no token") {
        localStorage.removeItem("username");
        localStorage.removeItem("date");
        localStorage.removeItem("page");
        localStorage.removeItem("token");
        document.cookie = "C-X-ssoJwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location = process.env.REACT_APP_SSO_LOGIN_URL;
        // return this.renderLoginForm();
      } else {
        return (
          <Redirect
            to={{
              pathname: "/home",
            }}
          />
        );
      }
    }
    return null;
  }
}

export default withStyles(useStyles)(Login);
