import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function PrivateRoutes({ component: Component, ...rest }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const hasJWT = () => {
            const token = localStorage.getItem("token");
            if (token) {
                const parsedToken = token.replace("Bearer", "").trim();
                
                try {
                    const user = jwtDecode(parsedToken);

                    if (new Date().getTime() > user.exp * 1000) {
                        localStorage.removeItem("token");
                        setIsAuthenticated(false);
                        setSnackbarOpen(true); 
                        window.location.href = process.env.REACT_APP_SSO_LOGOUT_URL
                    } else {
                        setIsAuthenticated(true);
                    }
                } catch (e) {
                    console.error("Token decoding error:", e);
                    localStorage.removeItem("token");
                    setIsAuthenticated(false);
                    setSnackbarOpen(true); 
                    window.location.href = process.env.REACT_APP_SSO_LOGOUT_URL
                }
            } else {
                setIsAuthenticated(false);
                window.location.href = process.env.REACT_APP_SSO_LOGOUT_URL
            }

            setLoading(false);
        };

        hasJWT();
        const intervalId = setInterval(hasJWT, 5000);
        return () => clearInterval(intervalId);
    }, [history]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        window.location.href = process.env.REACT_APP_SSO_LOGOUT_URL
                    )
                }
            />
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <MuiAlert onClose={handleSnackbarClose} severity="warning">
                    Logging out...
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default PrivateRoutes;
