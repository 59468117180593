import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SearchBar from "material-ui-search-bar";
import { Icon } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 400,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const EditForm = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [alert, setAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  // Change for Search bar filter
  const [filteredItemsofLeft, setfilteredItemsofLeft] = React.useState([]);
  const [filteredItemsofRight, setfilteredItemsofRight] = React.useState([]);

  // function to get lists of alloted and remaining items
  const getItems = () => {
    console.log(props.edit);
    var url;
    if (props.edit === "activity")
      url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/${props.edit}-view/${props.item.employee_code}/${props.item.id}/`;
    else
      url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/${props.edit}-view/${props.item.id}/`;
    console.log(url);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      console.log(data);
      setLeft(data["free"]);
      //Change for filter
      setfilteredItemsofLeft(data["free"]);
      setRight(data["busy"]);
      //Change for filter
      setfilteredItemsofRight(data["busy"]);
    });
  };

  React.useEffect(() => {
    getItems();
  }, [props.item.id]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (item) => () => {
    const currentIndex = checked.indexOf(item);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  // const handleCheckedRight = () => {
  //   setRight(right.concat(leftChecked));
  //   console.log("lcheck", leftChecked);
  //   setLeft(not(left, leftChecked));
  //   setChecked(not(checked, leftChecked));
  //   console.log("r", right);
  // };

  // const handleCheckedLeft = () => {
  //   setLeft(left.concat(rightChecked));
  //   setRight(not(right, rightChecked));

  //   setChecked(not(checked, rightChecked));
  // };
  const handleCheckedRight = () => {
    for (let i = 0; i < leftChecked.length; i++) {
      setRight(right.push(leftChecked[i]));
    }
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    for (let i = 0; i < leftChecked.length; i++) {
      setLeft(left.splice(left.indexOf(leftChecked[i]), 1));
    }

    setfilteredItemsofRight(
      right.sort((a, b) => {
        if (a.display_name < b.display_name) return -1;
        if (a.display_name > b.display_name) return 1;
        return 0;
      })
    );
    setLeft(left);
    setRight(right);
    console.log("L lenght", left.length);
    console.log("R lenght", right.length);
  };

  const handleCheckedLeft = () => {
    for (let i = 0; i < rightChecked.length; i++) {
      setLeft(left.push(rightChecked[i]));
    }
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    for (let i = 0; i < rightChecked.length; i++) {
      setRight(right.splice(right.indexOf(rightChecked[i]), 1));
      console.log(right.indexOf(rightChecked[i]));
    }
    console.log(left);

    setfilteredItemsofLeft(
      left.sort((a, b) => {
        if (a.display_name < b.display_name) return -1;
        if (a.display_name > b.display_name) return 1;
        return 0;
      })
    );
    setLeft(left);
    setRight(right);
    console.log("L lenght", left.length);
    console.log("R lenght", right.length);
  };

  // send post request with new entries
  async function sendRequest(url, obj) {
    const response= await fetch(url, {
      // mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(obj),
    })
    return response;
  }

  // submit changes to the backend on save button click
  // test comment
  async function handleSubmit() {
    var obj = {
      free: left,
      busy: right,
    };
    var url;
    if (props.edit === "activity")
      url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/${props.edit}-view/${props.item.employee_code}/${props.item.id}/`;
    else
      url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/${props.edit}-view/${props.item.id}/`;
    console.log(url);
    const response= await sendRequest(url, obj);
    if (response.status === 200) {
      setAlert(true);
    }
    else {
      setAlert(true);
      setErrorMessage(response.statusText);
    }
    setfilteredItemsofLeft(
      left.sort((a, b) => {
        if (a.display_name < b.display_name) return -1;
        if (a.display_name > b.display_name) return 1;
        return 0;
      })
    );
    console.log("Updated left ", left);
    setfilteredItemsofRight(
      right.sort((a, b) => {
        if (a.display_name < b.display_name) return -1;
        if (a.display_name > b.display_name) return 1;
        return 0;
      })
    );
  }

  // remove alert after 5000ms
  const handleAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setAlert(false);
  };

  // build left and right side lists
  const customList = (title, items, filteredItems) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <SearchBar
        // value={this.state.searchValue}
        searchIcon={
          <Icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return null;
            }}
          />
        }
        closeIcon={
          <Icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return null;
            }}
          />
        }
        onChange={(newValue) => {
          //console.log(newValue, items, title, filteredItems, " HERE");
          filteredItems = items.filter((item) =>
            `${item.display_name.toLowerCase()} ${item.id}`.includes(
              newValue.toLowerCase()
            )
          );
          // Filter List based on serach bar
          if (title === "Choices") {
            setfilteredItemsofLeft(filteredItems);
          } else if (title === "Chosen") {
            setfilteredItemsofRight(filteredItems);
          }
        }}
        // onRequestSearch={() => doSomethingWith(this.state.value)}
      />
      <List className={classes.list} dense component="div" role="list">
        {filteredItems.map((item, idx) => {
          const labelId = `transfer-list-all-item-${idx}-label`;

          return (
            <ListItem
              key={idx}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              {props.edit === "invoice" ? (
                <ListItemText
                  id={labelId}
                  primary={`${item.display_name} - ${item.id}`}
                />
              ) : (
                <ListItemText id={labelId} primary={item.display_name} />
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  return (
    <div>
      <h2>{props.item.display_name}</h2>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>{customList("Choices", left, filteredItemsofLeft)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="medium"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            {props.isEmployee !== "employee" ? (
              <Button
                variant="outlined"
                size="medium"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>{customList("Chosen", right, filteredItemsofRight)}</Grid>
      </Grid>
      <br />
      <br />

      <Button color="primary" variant="contained" onClick={handleSubmit}>
        Save
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert}
        autoHideDuration={5000}
        onClose={handleAlert}
      >
        <Alert onClose={handleAlert} severity={errorMessage ? 'error' : 'success'}>
          {errorMessage || 'Recorded'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditForm;
