import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { Pagination } from '@mui/material';

// Function to measure the width of a text
const getTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

const headerMapping = {
  "TM CID": "tm_cid",
  "TM PID": "tm_pid",
  "TM STL ID": "tm_stl_id",
  "Client's Name": "client_name",
  "Project Name": "project_name",
  "Sub Project Name": "sub_project_name",
  "Task ID": "task_id",
  "Task Name": "task_name",
  "Status": "status",
  "Assignee": "assignee",
  "Priority": "priority",
  "Due Date": "due_date",
  "Start Date": "start_date",
  "Date Created": "date_created",
  "Date Closed": "date_closed",
  "Date Done": "date_done",
  "Time Logged": "time_logged",
  "Time Estimate": "time_estimate",
  "Tag": "tag",
  "No of records processed": "no_of_records_processed",
  "Error": "error"
};

const DataTable = ({ rows, classes }) => {
  const [columnWidths, setColumnWidths] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showErrorRows, setShowErrorRows] = useState(false); // State for showing error rows

  useEffect(() => {
    if (!rows.length) return;

    const measuredWidths = {};
    const font = '14px Arial';

    // Measure the width of headers and data cells
    Object.keys(headerMapping).forEach(header => {
      const field = headerMapping[header];
      measuredWidths[header] = getTextWidth(header, font);

      rows.forEach(row => {
        const cellText = row[field] || '';
        const textWidth = getTextWidth(cellText, font);
        measuredWidths[header] = Math.max(measuredWidths[header] || 0, textWidth);
      });
    });

    const adjustedWidths = {};
    Object.keys(measuredWidths).forEach(key => {
      adjustedWidths[key] = measuredWidths[key] + 20; // Adding padding for better visibility
    });

    setColumnWidths(adjustedWidths);
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleShowErrorRowsChange = (event) => {
    setShowErrorRows(event.target.checked);
  };

  const filteredRows = showErrorRows
  ? rows.filter(row => row.error !== "" || row.error)
  : rows;
  const totalPages = Math.ceil(filteredRows.length / rowsPerPage);
  const paginatedRows = filteredRows.slice((page-1) * rowsPerPage, page * rowsPerPage);

  return (
    <Grid container alignItems="flex-start">
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={showErrorRows}
              onChange={handleShowErrorRowsChange}
              color="primary"
            />
          }
          label="Show error rows"
        />
      </Grid>
      <Grid item xs>
        <TableContainer className={classes.tableContainer} component={Paper} style={{ marginTop: 20 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#8ed6d7" }}>
                {Object.keys(headerMapping).map(header => (
                  <TableCell key={header} className={classes.tableCell} style={{ width: columnWidths[header] }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map(row => (
                <TableRow
                  key={row.task_id}
                  className={
                    row.status_task === 'error' || row.error ? classes.errorRow :
                      row.status_task === 'created' ? classes.createdRow :
                        row.status_task === 'updated' ? classes.updatedRow :
                          classes.tableRow
                  }
                >
                  {Object.keys(headerMapping).map(header => (
                    <TableCell key={header} className={classes.tableCell} style={{ width: columnWidths[header] }}>
                      {row[headerMapping[header]] === 'nan' ? '' : row[headerMapping[header]]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <div style={{ padding: '10px'}}>
              {rows.length === 0 &&
                <Typography variant="body1" align="center">
                  No&nbsp;Records&nbsp;Found
                </Typography>

              }
              </div>
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            style={{ marginTop: 20 }}
          />
      </Grid>
    </Grid>

  );
};

export default DataTable;
