/* eslint-disable */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  format,
  addDays,
  startOfMonth,
  endOfMonth,
  isSameDay,
  startOfISOWeek,
} from "date-fns";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  withStyles,
  LinearProgress,
  Box,
  Typography,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import amber from "@material-ui/core/colors/amber";
import Footer from "./Footer";
import Marquee from "react-fast-marquee";
import MonthToggle from "./MonthToggle";
import SingleTable from "./SingleTable";
import Team1 from "./Team1";
import BackdatedTimesheet from "./BackdatedTimesheet";
import "./Timesheet.css";
import ProjectInfo from "./ProjectInfo";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// custom component for progress bar by
// changing style of exisiting component
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    textAlign: "center",
    "&:disabled": {
      color: "#212121",
    },
  },
  pending: {
    flexGrow: 1,
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  greet: {
    flexGrow: 1,
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    height: "120%",
    width: "120%",
    //position: "relative",
  },
});

const CustomTextField = withStyles({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield', // For Firefox
    },
  },
})(TextField);


class Timesheet extends React.Component {
  constructor(props) {
    super(props);
    this.projectFilterRef = React.createRef();
    this.state = {
      // role and designation flags
      is_hr: this.props.user["is_hr"],
      is_stl: this.props.user["is_stl"],
      is_tech: this.props.user["is_tech_lead"],
      is_func: this.props.user["is_functional_lead"],
      is_super: this.props.user["is_superuser"],
      is_rm: this.props.user["is_rm"],
      //is_active: true,
      activeTab : {myTimesheet:true, teamManagement:false, backDatedTimesheet:false, projectInfo:false},
      is_loading: true,
      is_submit_loading: false,
      timesheetData: [],
      marQueeText: "",
      marQueeId: process.env.REACT_APP_MARQUEE_ID,
      // for storing all the invoice and
      // activity id for leave and holiday
      leave: {},

      // for storing all rows data (for left table)
      rows: [],

      //storing current day, lastmonday etc for clientquery
      client_query_disabled_dates: {
        formatLastMonday: "",
        formatToday: "",
      },

      // for storing days headers and
      // hour data according to month
      daysHeader: [],
      dayRows: [],
      rejectedDates: {},
      totalDateHours: {},
      prevDateValues: {},
      allDatesValues: {},
      allDatesMatrix: {},

      //for storing client response
      clientResponse: [],

      // for separate alert of holidays
      holiDates: {},

      // right click popup variables
      description: "",
      clientQuery: false,
      invalidDescLength: false,
      allDatesDescriptions: {},
      allDatesClientQuery: {},
      currSelectedDate: "",
      currSelectedCell: "",
      currSelectedValue: "",

      // validation for total hours
      invalidTtlHours: false,

      // for storing employee details
      employee: {},
      currDate: new Date(localStorage.getItem("date")),

      // for storing footer info
      applicable: 0,
      clocked: 0,
      leaves: 0,
      holidays: 0,
      joining: "",

      pending: [],
      currentTable: "",
      progress: 0,

      // alerts and dialog
      noChange: false,
      submitAlert: false,
      totalHourAlert: false,
      leaveHourAlert: false,
      totalHoliHourAlert: false,
      fridayAlert: false,
      clientValueSubmit: false,
      clientValueAlert: false,
      descriptionDialog: false,
      fillHourAlert: false,
      configDataByName: {}
    };

    this.getClientValue = this.getClientValue.bind(this);
    this.handleMonthToggle = this.handleMonthToggle.bind(this);
    this.getEmployeeDetails = this.getEmployeeDetails.bind(this);
    this.fetchHolidays = this.fetchHolidays.bind(this);
    this.fetchFooterInfo = this.fetchFooterInfo.bind(this);
    this.fetchTimesheetConfig = this.fetchTimesheetConfig.bind(this);
    this.fetchTimesheetConfigPost = this.fetchTimesheetConfigPost.bind(this)
    this.fetchTimesheetConfigByNamePost = this.fetchTimesheetConfigByNamePost.bind(this)
    this.setData = this.setData.bind(this);
    this.checkTimesheet = this.checkTimesheet.bind(this);
    this.fetchCombinationDays = this.fetchCombinationDays.bind(this);
    this.fetchCombinationDaysPost = this.fetchCombinationDaysPost.bind(this)
    this.postHours = this.postHours.bind(this);
    this.updateHours = this.updateHours.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.makeRequests = this.makeRequests.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitClientResHandler = this.submitClientResHandler.bind(this);
    this.handleNoChange = this.handleNoChange.bind(this);
    this.handleSubmitAlert = this.handleSubmitAlert.bind(this);
    this.handleHourAlert = this.handleHourAlert.bind(this);
    this.handleLeaveHourAlert = this.handleLeaveHourAlert.bind(this)
    this.handleHoliHourAlert = this.handleHoliHourAlert.bind(this);
    this.handleFillHourAlert = this.handleFillHourAlert.bind(this);
    this.handleFridayValueAlert = this.handleFridayValueAlert.bind(this);
    this.handleFridaySubmitAlert = this.handleFridaySubmitAlert.bind(this);
    this.postClientValue = this.postClientValue.bind(this);
    this.handleFridaySubmit = this.handleFridaySubmit.bind(this);
    this.getDaysHeader = this.getDaysHeader.bind(this);
    this.getDays = this.getDays.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDialogChange = this.handleDialogChange.bind(this);
    this.submitDialog = this.submitDialog.bind(this);
    this.changeState = this.changeState.bind(this);
    this.renderTimesheet = this.renderTimesheet.bind(this);
    this.renderApproverSheet = this.renderApproverSheet.bind(this);
    this.backdatedTimesheet = this.backdatedTimesheet.bind(this)
    this.projectinfotimesheet = this.projectinfotimesheet.bind(this)
  }

  // populate the initial database data on component mount
  async componentDidMount() {
    // fetching country code
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/employee-details/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var empData = await res.json();
      // console.log("holi id", empData.holiday_calendar);
      var country_cd = 3;
      if (empData.holiday_calendar) {
        country_cd = empData.holiday_calendar;
        this.setState({
          country_code: country_cd,
        });
      }

      if (this.state.country_code === 4) {
        // console.log("inside if country_code", this.state.country_code);
        if (format(new Date(), "EEE") === "Thu")
          this.getClientValue(format(new Date(), "yyyy-MM-dd"));
      } else {
        if (format(new Date(), "EEE") === "Fri")
          this.getClientValue(format(new Date(), "yyyy-MM-dd"));
      }
    });

    var date = localStorage.getItem("date");
    // console.log("local storage date: ", date);

    this.setState(
      {
        currDate: new Date(date),
        progress: 0,
      },
      this.getEmployeeDetails()
    );
  }

  // check if client value feedback filled on friday, if not show dialog
  getClientValue(date) {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/client-value/${date}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var data = await res.json();
      // console.log("client value data: ", data);
      if (data.length === 0) this.setState({ fridayAlert: true });
    });
  }

  // get employee details
  getEmployeeDetails() {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/employee-details/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(async (res) => {
      var empData = await res.json();
      //var tmsData = await this.fetchTimesheetData();
      var tmsData = await this.fetchTimesheetDataPost()
      var date = localStorage.getItem("date");
      var currMonth = format(new Date(date), "M");
      var currYear = format(new Date(date), "yyyy");
      var country =
        empData.holiday_calendar === "" ? 1 : empData.holiday_calendar;
      var footerInfo = await this.fetchFooterInfo(currMonth, currYear, country);

      // console.log(footerInfo);
      this.setState(
        {
          employee: empData,
          timesheetData: tmsData,
        },
        async function () {
          if (localStorage.getItem("page") === "approver") {
            this.renderApproverSheet();
          } else if (localStorage.getItem("page") === "backdated") {
            this.backdatedTimesheet();
          }
          else if (localStorage.getItem("page") === "projectinfo") { 
            this.projectinfotimesheet();  
          }else
            await this.setData(
              this.state.currDate,
              this.props.eid,
              footerInfo[4]
            );
        }
      );
    });
  }

  // send client response status to backend
  async postClientQueryResponse(clientQueryResponse) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/client-response/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "clientResponse": clientQueryResponse }),
      }
    ).then((res) => res);
  }

  // fetch holidays applicable to the user for current month, year
  async fetchHolidays(country, month, year) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/holiday/${country}/${month}/${year}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch all hour type details on footer
  async fetchFooterInfo(month, year, country) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/parameter-value/${month}/${year}/${country}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch config value with id
  async fetchTimesheetConfig(id) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value/${id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  async fetchTimesheetConfigPost() {
    const marQueeId= this.state.marQueeId;
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value/abcd/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "config_ids": [1, 2, 3, marQueeId] })
      }
    ).then((res) => res.json());
  }

  async fetchTimesheetConfigByNamePost() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/config-value-by-name/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ "config_names": ["Total Hours in Day"] })
      }
    ).then((res) => res.json());
  }

  // fetch all projects, clients and activities corresponding to each project
  async fetchTimesheetData() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/emp-invoice-activity/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // POST API to fetch users timesheet invoices/activities
  async fetchTimesheetDataPost() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/emp-invoice-activity/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({"date":localStorage.getItem('date')})
      }
    ).then((res) => res.json());
  }

  // for leave activity control cell disabiltiy
  disableLeaveActivity(act_id, currMonthAllowDay, day) {
    let currentDate = new Date()
    if (act_id === 125) { 
      if (currentDate.getDate() > parseInt(currMonthAllowDay) && day.getMonth() != currentDate.getMonth()) { 
        return false
      }
      return true
    }
    return false
  }
  
  // fetch all hour data for all project-activity combination
  async setData(currDate, code, joining) {
    var currMonth = format(currDate, "M");
    var currYear = format(currDate, "yyyy");
    var country =
      this.state.employee.holiday_calendar === ""
        ? 1
        : this.state.employee.holiday_calendar;
    var holidayDates = await this.fetchHolidays(country, currMonth, currYear);
    // console.log("holiday dates: ", holidayDates);

    var footerInfo = await this.fetchFooterInfo(currMonth, currYear, country);
    var joining_date = new Date(footerInfo[4]);
    var totalHours = this.state.totalDateHours;
    var dateKeys = Object.keys(totalHours);
    if (
      joining_date.getMonth() === currMonth &&
      joining_date.getFullYear() === currYear
    ) {
      for (let i = 0; i < joining_date.getDate() - 1; i++) {
        totalHours[dateKeys[i]] = 0;
      }
    }

    var clockedHours = footerInfo[0],
      leaveHours = footerInfo[1];
    var holidayHours = footerInfo[2],
      pendingDates = footerInfo[3];

    /////////////////// Previous Code to fetch Timesheet Config /////////////////////////////////////////////
    // var numWeeksData = await this.fetchTimesheetConfig(1);
    // var numWeeks = parseInt(numWeeksData[0]["value"]);
    // // console.log("num weeks: ", numWeeks);

    // var currMonthAllowData = await this.fetchTimesheetConfig(2);
    // var currMonthAllowDay = currMonthAllowData[0]["value"];
    // // console.log("curr month allow day: ", currMonthAllowDay);

    // var prevMonthAllowData = await this.fetchTimesheetConfig(3);
    // var prevMonthAllowWeeks = prevMonthAllowData[0]["value"];
    // console.log("prev month allowed weeks: ", prevMonthAllowWeeks);
    ////////////////////////////////////  End ////////////////////////////////////////////////////////////////


    ///////////////////////////////////  NEW CODE TO FETCH TIMESHEET CONFIG /////////////////////////////////
    var configData = await this.fetchTimesheetConfigPost()
    var numWeeks = parseInt(configData.filter(obj => obj.id == 1)[0]['value'])
    var currMonthAllowDay = configData.filter(obj => obj.id == 2)[0]['value']
    var prevMonthAllowWeeks = configData.filter(obj => obj.id == 3)[0]['value']
    this.state.marQueeText= (configData.filter(obj => obj.id == this.state.marQueeId)[0] || {})['value'] || '';

    //////////////////////////////////////// END ///////////////////////////////////////////////////////////

    /////////////////////////////////// Fetch Total Hours in Day  //////////////////////////////////////////

    var configDataByName = await this.fetchTimesheetConfigByNamePost()

    //////////////////////////////////////// END //////////////////////////////////////////////////////////

    var days = [];
    var headers = this.getDaysHeader(currDate, holidayDates, joining_date);

    var timesheetData = this.state.timesheetData;
    var row = {},
      rows = [];

    var hourTotal = 0,
      count = 0;
    for (let i = 0; i < timesheetData.length; i++)
      hourTotal += timesheetData[i]["activities"].length;
    var currProgress = this.state.progress;

    /////// Fetch user's timesheet/hour data in one go  to avoid calling APIS in loop///////
    var usersTimesheetData = { "code": code, "currMonth": currMonth, "currYear": currYear }
    var proIdArr = []
    var actIdArr = []
    for (let i = 0; i < timesheetData.length; i++) {
      for (let j = 0; j < timesheetData[i]["activities"].length; j++) {
        if (localStorage.getItem("page") !== "approver") {
          var pro_id = timesheetData[i]["invoice_id"],
            act_id = timesheetData[i]["activities"][j]["id"];
          proIdArr.push(pro_id)
          actIdArr.push(act_id)
        }
      }
    }

    usersTimesheetData['pro_id'] = proIdArr
    usersTimesheetData['act_id'] = actIdArr
    //console.log(usersTimesheetData, ' ----------')

    var usersHoursData = await this.fetchCombinationDaysPost(usersTimesheetData)

    //////////////////////// END ////////////////////////////////////////////////////////////////

    for (let i = 0; i < timesheetData.length; i++) {
      for (let j = 0; j < timesheetData[i]["activities"].length; j++) {
        if (localStorage.getItem("page") !== "approver") {
          var pro_id = timesheetData[i]["invoice_id"],
            act_id = timesheetData[i]["activities"][j]["id"];
          var id = parseInt(`${pro_id}${act_id}`);
          var isRemoved = timesheetData[i]["activities"][j]["is_removed"]

          ////////////////////// Previous code to Fetch users hour data ////////////////////////////
          // var hourData = await this.fetchCombinationDays(
          //   pro_id,
          //   act_id,
          //   currMonth,
          //   code,
          //   currYear
          // );
          ///////////////////////////// End ///////////////////////////////////////////////////////////

          ///////////////////////////////////////////// NEW CODE /////////////////////////////////////
          var hourData = usersHoursData.filter((obj) => obj.employee_code == code && obj.activity == act_id && obj.invoice == pro_id)

          //console.log(hourData, ' ----- hourdata')
          //////////////////////////////////////  END ///////////////////////////////////////////////

          var d = this.getDays(
            hourData,
            pro_id,
            act_id,
            currDate,
            holidayDates,
            numWeeks,
            currMonthAllowDay,
            prevMonthAllowWeeks,
            isRemoved
          );
          var total = 0;
          for (let j = 0; j < hourData.length; j++) {
            total += parseFloat(hourData[j].hour);
          }

          var status = "Approved";
          for (let j = 0; j < hourData.length; j++) {
            if (hourData[j].status === -1) {
              status = "Rejected";
              break;
            }
          }

          days.push(<TableRow key={id}>{d}</TableRow>);

          row = {
            id: id,
            code: code,
            client: timesheetData[i]["client_name"],
            pro_id: pro_id,
            project: timesheetData[i]["invoice_name"],
            act_id: act_id,
            activity: timesheetData[i]["activities"][j]["activity_name"],
            total: total,
            approval: status,
            days: d,
            isRemoved: isRemoved
          };
          rows.push(row);
          count++;
          currProgress = (count / hourTotal) * 100;
          this.setState({ progress: currProgress });
        }
      }
    }
    if (localStorage.getItem("page") !== "approver") {
      this.setState({
        progress: 100,
        rows: rows,
        daysHeader: headers,
        dayRows: days,
        currDate: currDate,
        clocked: clockedHours,
        leaves: leaveHours,
        holidays: holidayHours,
        pending: pendingDates,
        holiDates: holidayDates,
        is_loading: false,
        is_submit_loading: false,
        configDataByName: configDataByName
      });

      this.renderTimesheet(joining);
    } else {
      this.setState({
        daysHeader: headers,
        currDate: currDate,
        holiDates: holidayDates,
        configDataByName: configDataByName
      });
    }
    this.projectFilterRef.current.monthToggleHandler(this.state.rows);
  }

  // fetch hour data for a specific project-activity combination
  async fetchCombinationDays(pro, act, currMonth, code, currYear) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-activity-invoice-list-month/${code}/${pro}/${act}/${currMonth}/${currYear}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // fetch hour data for a specific project-activity combination in one go POST
  async fetchCombinationDaysPost(usersTimesheetData) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-activity-invoice-list-month/code/1/1/1/1/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(usersTimesheetData),
      }
    ).then((res) => res.json());
  }


  // check if there is a record for a specific project-activity on a given date
  async checkTimesheet(pro, act, date) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-check/${pro}/${act}/${date}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => res.json());
  }

  // request to create a new timesheet record
  async postHours(dateObj) {
    dateObj.hour = parseFloat(dateObj.hour);
    //console.log("posthours", dateObj);
    await fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-list/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dateObj),
      }
    );
  }

  // request to update a new timesheet record
  async updateHours(dateObj) {
    dateObj.hour = parseFloat(dateObj.hour);
    
    await fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/timesheet-details/${dateObj.id}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(dateObj),
      }
    );
  }

  // handle state changes on typing in any cell
  handleHourChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    value = Number(value);
    value = value.toFixed(2);

    /// get total hours of the day
    var totalHrsHoursInDay = parseFloat(this.state.configDataByName['Total Hours in Day'])

    // // leave logic
    // if(name.includes('/')) {
    //     var pro_id = name.split("/p")[1].split("/")[0]
    //     var act_id = name.split("/a")[1]

    //     if(
    //         this.state.leave["project"].toString() === pro_id
    //         && this.state.leave["activity"].toString() === act_id
    //         && value !== "8" && value !== ""
    //     ) {
    //         // console.log("leave filled")
    //         return (
    //             alert("Leave hours should be equal to 8")
    //         )
    //     }
    // }

    value = value === "" ? 0 : parseFloat(value);

    var element = document.getElementById(name);
    var date = element.dataset.date;
    var newTotal = this.state.totalDateHours[date];
    if (name.includes("/") && this.state.allDatesValues[name] === 0) {
      newTotal += value;
      // console.log("New Total", newTotal);
      // validation for total hours
      if (newTotal > totalHrsHoursInDay || newTotal < 0) {
        this.setState({ totalHourAlert: true, invalidTtlHours: true });
      } else {
        this.setState({ totalHourAlert: false, invalidTtlHours: false });
      }
    } else {
      newTotal += value - this.state.allDatesValues[name];
      // console.log("New Total", newTotal);
      // validation for total hours
      if (newTotal > totalHrsHoursInDay || newTotal < 0) {
        this.setState({ totalHourAlert: true, invalidTtlHours: true });
      } else {
        this.setState({ totalHourAlert: false, invalidTtlHours: false });
      }
      // console.log(
      //   "inside hour change",
      //   this.state.totalHourAlert,
      //   this.state.invalidTtlHours
      // );
    }

    // console.log("total date hours: ", this.state.totalDateHours[date]);
    // console.log("existing value: ", this.state.allDatesValues[name]);
    // console.log("new total: ", newTotal);
    // if (this.state.totalDateHours[date] === 0) {
    //   return alert("Total hours can not be 0 !");
    // }

    // Leave Hour Logic 
    if (name.endsWith('/a125')) { 
      if (value > 8) {
        this.setState({ leaveHourAlert: true, invalidTtlHours: true })
      }
      else { 
        this.setState({leaveHourAlert:false, invalidTtlHours: false})
      }
    }
    
    this.setState({
      allDatesMatrix: {
        ...this.state.allDatesMatrix,
        [name]: value,
      },
      allDatesValues: {
        ...this.state.allDatesValues,
        [name]: value,
      },
      totalDateHours: {
        ...this.state.totalDateHours,
        [date]: newTotal,
      },
    });
  }

  // make requests for all new/updated entries
  async makeRequests() {
    var allDates = this.state.allDatesMatrix;
    var keys = Object.keys(allDates);

    var dateObj = {},
    changes = false,
    updateList = [];

if (!this.state.invalidTtlHours) {
    // Step 1: Loop through the keys and calculate the difference between current and previous values
    for (let i = 0; i < keys.length; i++) {
        var element = document.getElementById(keys[i]);
        var status = parseInt(element.dataset.status);
        var value = allDates[keys[i]];
        var prevValue = this.state.prevDateValues[keys[i]];
        var description = this.state.allDatesDescriptions[keys[i]];
        var clientQuery = this.state.allDatesClientQuery[keys[i]];

        // Calculate the difference between the current and previous value
        var difference = value - prevValue;

        // Step 2: Add the entry to a list, including the difference
        updateList.push({
            key: keys[i],
            value: value,
            prevValue: prevValue,
            difference: difference, // Store the difference for sorting
            status: status,
            description: description,
            clientQuery: clientQuery,
            element: element, 
        });
    }

    // Step 3: Sort the updateList based on the `difference` (most negative first)
    updateList.sort((a, b) => a.difference - b.difference);

    // Step 4: Process each item in the sorted order
    for (let i = 0; i < updateList.length; i++) {
        var entry = updateList[i];
        var element = entry.element;
        var difference = entry.difference;
        var value = entry.value;
        var prevValue = entry.prevValue;
        var status = entry.status;
        var description = entry.description;
        var clientQuery = entry.clientQuery;
        var key = entry.key;

        // POST method for new entries
        if (key.includes("/") && value > 0) {
            changes = true;
            var pro_id = key.split("/p")[1].split("/")[0];
            var act_id = key.split("/a")[1];
            var date = key.split("/")[0];
            var existing_id = await this.checkTimesheet(pro_id, act_id, date);

            if (existing_id === -1) {
                // console.log("post query");
                dateObj = {
                    date: date,
                    hour: value,
                    activity: act_id,
                    status: 0,
                    description: description,
                    is_client_query: clientQuery,
                    datedim: date,
                    employee_code: this.props.eid,
                    invoice: pro_id,
                };
                await this.postHours(dateObj);
            } else {
                // console.log("update query");
                dateObj = {
                    id: existing_id,
                    hour: value,
                    status: 0,
                    description: description,
                    is_client_query: clientQuery,
                    update_date: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                };
                await this.updateHours(dateObj);
            }
        }
        // PUT method
        else if (prevValue !== value) {
            changes = true;
            dateObj = {
                id: parseInt(key),
                hour: value,
                status: status === -1 || status === 1 ? 1 : status,
                description: description,
                is_client_query: clientQuery,
                update_date: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
            };

            await this.updateHours(dateObj);

            if (status === 0 && element.style.background !== "")
                element.style.background = "white";
            else if (status !== 0) element.style.background = amber[400];
        }
    }
  }
   return changes;
  }

  // handle all changes and send to backend on submit button click
  async handleSubmit(e) {
    this.setState({ is_submit_loading: true });
    // // validation for total of 8-16 hours for each date
    // var totalHours = this.state.totalDateHours;
    // console.log("TOTAL HOURS", totalHours);

    // var dateKeys = Object.keys(totalHours);

    // console.log("DATE KEYS", dateKeys);

    // var holidayDates = this.state.holiDates;
    // console.log("HOLIDAY DATES", holidayDates);

    // for (let i = 0; i < 31; i++) {
    //   var day = new Date(dateKeys[i]);
    //   console.log("Totalhours", totalHours[dateKeys[i]]);
    //   console.log("Datekeys", dateKeys[i]);
    //   // This is for holidays
    //   if (
    //     (holidayDates.includes(dateKeys[i]) || format(day, "EEEEE") === "S") &&
    //     totalHours[dateKeys[i]] > 16
    //   ) {
    //     console.log("holiday: ", dateKeys[i]);
    //     this.setState({ totalHoliHourAlert: true });
    //     return;
    //   } else if (
    //     !(holidayDates.includes(dateKeys[i]) || format(day, "EEEEE") === "S")
    //   ) {
    //     this.setState({ totalHourAlert: true });
    //     console.log("Working day condtion ");
    //     return;
    //   } else if (totalHours[dateKeys[i]] == 0) {
    //     this.setState({ totalHourAlert: true });
    //     console.log("Zero condition ");
    //     return;
    //   } else if (totalHours[dateKeys[i]] < 8 || totalHours[dateKeys[i]] > 16) {
    //     this.setState({ totalHourAlert: true });
    //     console.log("8-16 condition");
    //     return;
    //   }

    ///////////////// COMMENTED DUE TO ISSUE IN ORACLE ///////////////////////
    // post Client Query response status
    
    
    var temp_res_changes = await this.postClientQueryResponse(
      this.state.clientResponse
    );
    let res_changes = false;
    if (temp_res_changes.status === 201) {
      res_changes = true;
    }
    

    ////////////////////// END COMMENT //////////////////////////////////////////

    var changes = await this.makeRequests();

    this.setState({
      totalDateHours: {},
    });
    await this.setData(this.state.currDate, this.props.eid);
    this.projectFilterRef.current.dropdownChangeHandler();

    localStorage.setItem("date", format(this.state.currDate, "yyyy-MM-dd"));

    var date = new Date(this.state.currDate);
    var currMonth = format(date, "M");
    var currYear = format(date, "yyyy");
    var country =
      this.state.employee.holiday_calendar === ""
        ? 1
        : this.state.employee.holiday_calendar;
    var footerInfo = await this.fetchFooterInfo(currMonth, currYear, country);
    var clockedHours = footerInfo[0],
      leaveHours = footerInfo[1];
    var holidayHours = footerInfo[2],
      pendingDates = footerInfo[3];

    this.setState(
      {
        clocked: clockedHours,
        leaves: leaveHours,
        holidays: holidayHours,
        pending: pendingDates,
      },
      async function () {
        
        ///////////// COMMENTED FOR OPTIMIZATION ///////////////////////////////////////////
        // footerInfo = await this.fetchFooterInfo(currMonth, currYear, country);
        // clockedHours = footerInfo[0];
        // leaveHours = footerInfo[1];
        // holidayHours = footerInfo[2];
        // pendingDates = footerInfo[3];
        ////////////////////////// END     ////////////////////////////////////////////////

        if (!changes && !res_changes)
          this.setState({ noChange: true, is_submit_loading: false });
        else {
          this.setState({
            submitAlert: true,
            allDatesMatrix: {},
            leaves: leaveHours,
            holidays: holidayHours,
            pending: pendingDates,
            clocked: clockedHours,
            is_submit_loading: false,
          });
        }
        this.renderTimesheet();

        // if "changes" is false then no changes were made
        // as no request was done
      }
    );
  }

  // client query response handler
  submitClientResHandler(res) {
    this.setState({ clientResponse: res });
  }

  // close no changes alert
  handleNoChange(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ noChange: false });
  }

  // close submit alert
  handleSubmitAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ submitAlert: false });
  }

  // close working day hour alert of (8-16)
  handleHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ totalHourAlert: false });
  }

  // Leave hour alert
  handleLeaveHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ leaveHourAlert: false });
  }

  // close holiday hour alert of (<16)
  handleHoliHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ totalHoliHourAlert: false });
  }

  // close fill hour first alert when right click
  handleFillHourAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ fillHourAlert: false });
  }

  // close client value feedback less than 20 chars alert
  handleFridayValueAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ clientValueAlert: false });
  }

  // close client value feedback submitted alert
  handleFridaySubmitAlert(event, reason) {
    if (reason === "clickaway") return;
    this.setState({ clientValueSubmit: false });
  }

  // send client value feedback to backend
  async postClientValue(clientObj, date) {
    fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/timesheet/client-value/${date}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(clientObj),
      }
    );
  }

  // handle and submit friday alert details
  async handleFridaySubmit() {
    var value = document.getElementById("client-value").value;
    if (value.length < 20) this.setState({ clientValueAlert: true });
    else {
      var date = format(new Date(), "yyyy-MM-dd");
      var clientObj = {
        employee_code: this.props.eid,
        client_value: value,
        date: date,
      };

      // console.log("client value: ", clientObj);

      await this.postClientValue(clientObj, date);
      this.setState({
        clientValueSubmit: true,
        fridayAlert: false,
      });
    }
  }
  // get days headers with specific colors for holidays/current day
  getDaysHeader(currDate, holidayDates, joining_date) {
    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);
    let days = [];
    let day = monthStart;
    var holi = 0,
      totalDays = 0;

    var color = "white";
    while (day <= monthEnd) {
      const cloneDay = format(day, "d");
      const weekDay = format(day, "EEE");
      var date = format(day, "yyyy-MM-dd");

      if (holidayDates.includes(date)) {
        color = "#ededed";
        holi++;
      }

      if (isSameDay(day, new Date())) {
        color = "#4DBDC1";
      }
      // else if (format(day, "EEEEE") === "S") {
      //   color = "#ededed";
      //   holi++;
      // }

      if (isSameDay(day, new Date())) {
        days.push(
          <TableCell
            id="todayHeader"
            align="center"
            style={{ background: color }}
          >
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      } else {
        days.push(
          <TableCell align="center" style={{ background: color }}>
            {cloneDay}
            <br />({weekDay})
          </TableCell>
        );
      }

      color = "white";
      day = addDays(day, 1);
      totalDays++;
    }

    let holi_day = 0;
    let ttlday_joining = 0;
    if (
      joining_date.getMonth() === monthStart.getMonth() &&
      joining_date.getFullYear() === monthStart.getFullYear()
    ) {
      while (joining_date <= monthEnd) {
        var holi_date = format(joining_date, "yyyy-MM-dd");
        if (holidayDates.includes(holi_date)) {
          holi_day++;
          // console.log("HOLIDAY");
        }

        // if (format(joining_date, "EEEEE") === "S") {
        //   holi_day++;
        // }
        joining_date = addDays(joining_date, 1);
        ttlday_joining++;
      }

      holi = holi_day;
      totalDays = ttlday_joining;
    }

    var totalHours = totalDays * 8;
    var holidayHours = holi * 8;
    this.setState({
      //            applicable: totalHours,
      applicable: totalHours - holidayHours,
    });

    return days;
  }

  // get all styled cells with right click functionality for a project-activity combination
  getDays(
    data,
    pro_id,
    act_id,
    currDate,
    holidayDates,
    numWeeks,
    currMonthAllowDay,
    prevMonthAllowedEdits,
    isRemoved
  ) {
    var hours = {},
      days = [],
      rejectDates = this.state.rejectedDates;
    var dateHours = this.state.totalDateHours,
      vals = this.state.allDatesValues;
    var descriptions = this.state.allDatesDescriptions,
      queries = this.state.allDatesClientQuery;
    // var statuses = this.state.allStatus
    for (let i = 0; i < data.length; i++) {
      hours[data[i].date] = [
        parseFloat(data[i].hour),
        data[i].status,
        data[i].id,
        data[i].description,
        data[i].is_client_query,
      ];
    }

    const monthStart = startOfMonth(currDate);
    const monthEnd = endOfMonth(monthStart);
    var today = new Date();

    if (today > monthEnd) {
      today = monthEnd;
    }

    var currDateObj = new Date();
    var d = currDateObj.getDay();
    var diff = currDateObj.getDate() - d + (d == 0 ? -6 : 1);
    var lastMonday = new Date(currDateObj.setDate(diff));

    var lastUnfreezeDay = lastMonday.getDate() - numWeeks * 7;
    lastMonday.setDate(lastUnfreezeDay);

    var formatLastMonday = format(lastMonday, "yyyy-MM-dd");
    var formatToday = format(new Date(), "yyyy-MM-dd");
    // console.log("dates", formatLastMonday, formatToday);

    // code for not allowing to fill
    // prev month after currMonthAllowDay
    var todaysDay = format(new Date(), "d");
    var currMonth = format(new Date(), "M");
    if (currMonth != format(new Date(currDate), "M")) {
      var firstOfThisMonth = new Date();
      firstOfThisMonth.setDate(1);
      if (todaysDay > currMonthAllowDay) {
        formatLastMonday = format(firstOfThisMonth, "yyyy-MM-dd");
        // chnage first day of week for QIC team
        if (this.state.country_code === 4) {
          var QIC_formatLastMonday = new Date(formatLastMonday);
          formatLastMonday = format(
            QIC_formatLastMonday.setDate(QIC_formatLastMonday.getDate() + 1),
            "yyyy-MM-dd"
          );
        }
        // console.log(
        //   "todaysDay,currMonthAllowDay,formatLastMonday ",
        //   todaysDay,
        //   currMonthAllowDay,
        //   formatLastMonday
        // );
      } else {
        // var lastDayOfPrevMonth = new Date(firstOfThisMonth.setHours(-1))
        // var lastSeventhDay = lastDayOfPrevMonth.getDate() - 6
        // var prevMonLastSevenDay = lastDayOfPrevMonth.setDate(lastSeventhDay)
        // formatLastMonday = format(prevMonLastSevenDay, "yyyy-MM-dd")

        var prevMonthLastMonday = startOfISOWeek(monthEnd);

        lastUnfreezeDay =
          prevMonthLastMonday.getDate() - (prevMonthAllowedEdits - 1) * 7;
        prevMonthLastMonday.setDate(lastUnfreezeDay);
        formatLastMonday = format(prevMonthLastMonday, "yyyy-MM-dd");
        // console.log("formatelastmonday", formatLastMonday);
      }
    }

    // var latestDate = new Date()
    // latestDate.setFullYear(2020)
    // latestDate.setMonth(1)

    let day = monthStart;

    var val = "",
      id,
      color = "",
      status = 0,
      clr = "white",
      disable = true;

    // starting day sunday for QIC team
    if (this.state.country_code === 4) {
      var QIC_formatLastMonday = new Date(formatLastMonday);
      formatLastMonday = format(
        QIC_formatLastMonday.setDate(QIC_formatLastMonday.getDate() - 1),
        "yyyy-MM-dd"
      );
    }

    this.setState({
      client_query_disabled_dates: {
        formatLastMonday: formatLastMonday,
        formatToday: formatToday,
      },
    });
    while (day <= today) {
      var currDay = format(day, "yyyy-MM-dd");
      if (!dateHours.hasOwnProperty(currDay)) {
        dateHours[currDay] = 0;
      }

      if (currDay >= formatLastMonday && currDay <= formatToday) {
        // console.log("formatLastMonday", formatLastMonday);
        // console.log("currDay", currDay);
        // console.log("formatToday", formatToday);
        disable = false;
      }
      id = `${currDay}/p${pro_id}/a${act_id}`;

      descriptions[id] = "";
      queries[id] = false;
      if (hours.hasOwnProperty(currDay)) {
        val = hours[currDay][0];
        id = hours[currDay][2];
        status = hours[currDay][1];
        if (status === -1) {
          color = "red";
          //disable = false;
          rejectDates[currDay] = 1;
        } else if (status === 1) {
          color = amber[400];
        }
        dateHours[currDay] += val;
        descriptions[id] = hours[currDay][3];
        queries[id] = hours[currDay][4];
      }

      if (holidayDates.includes(currDay)) clr = "#ededed";
      if (isSameDay(day, new Date())) clr = "#4DBDC1";
      //else if (format(day, "EEEEE") === "S") clr = "#ededed";
      if (isRemoved == true) {
        // Removed Invoices/Activities /////
        days.push(
          <TableCell key={id} style={{ background: '#ededed' }}>
            <CustomTextField
              type="Number"
              //style={{ color: "grey" }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              inputProps={{
                "data-date": currDay,
                "data-status": status,
                min: 0,
                max: 24,
              }}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              //onContextMenu={this.showDialog}
              disabled={true}
              name={id}
              id={id}
              style={{ width: "5ch", background: color }}
              key={id}
              defaultValue={val}
              //onChange={this.handleHourChange}
            />
          </TableCell>
        );
      }
      else {
        days.push(
          <TableCell key={id} style={{ background: clr }}>
            <CustomTextField
              type="Number"
              //style={{ color: "white" }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              inputProps={{
                "data-date": currDay,
                "data-status": status,
                min: 0,
                max: 24,
              }}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              onContextMenu={this.showDialog}
              //disabled={act_id === 125 ? false : disable}
              disabled={this.disableLeaveActivity(act_id, currMonthAllowDay, day) ? false : disable}
              name={id}
              id={id}
              style={{ width: "5ch", background: color }}
              key={id}
              defaultValue={val}
              onChange={this.handleHourChange}
            />
          </TableCell>
        );
      }

      vals[id] = val === "" ? 0 : parseFloat(val);
      // statuses[id] = status

      val = "";
      color = "";
      clr = "white";
      status = 0;
      disable = true;
      day = addDays(day, 1);
    }

    this.setState({
      totalDateHours: dateHours,
      // allStatus: statuses,
      allDatesValues: vals,
      prevDateValues: vals,
      allDatesDescriptions: descriptions,
      allDatesClientQuery: queries,
      rejectedDates: rejectDates,
    });

    return days;
  }

  // show description dialog
  showDialog(e) {
    e.preventDefault();

    var name = e.target.name;
    var value = e.target.value;

    // console.log("name: ", name);
    // console.log("value: ", value);

    if (value === "") this.setState({ fillHourAlert: true });
    else {
      var element = document.getElementById(name);
      if (element.disabled === false) {
        var date = element.dataset.date;

        this.setState({
          description: this.state.allDatesDescriptions[name],
          clientQuery: this.state.allDatesClientQuery[name],
          currSelectedCell: name,
          currSelectedValue: value,
          currSelectedDate: format(new Date(date), "dd MMM yyyy"),
          descriptionDialog: true,
        });
      }
    }
  }

  // close description dialog
  closeDialog() {
    this.setState({
      descriptionDialog: false,
      invalidDescLength: false,
    });
  }

  // track changes in state variable of description dialog
  handleDialogChange(e) {
    var name = e.target.name,
      value;
    // validation for description length
    if (e.target.value.length < 256) {
      if (name === "description") value = e.target.value;
      else value = e.target.checked;

      // console.log("name: ", name);
      // console.log("value: ", value);

      this.setState({ [name]: value, invalidDescLength: false });
    } else {
      this.setState({ invalidDescLength: true });
    }
  }

  // store description dialog submit details
  submitDialog() {
    var name = this.state.currSelectedCell;

    // console.log("inside submit description: ", this.state.description);
    // console.log("client query: ", this.state.clientQuery);

    this.setState({
      allDatesMatrix: {
        ...this.state.allDatesMatrix,
        [name]: this.state.currSelectedValue,
      },
      allDatesDescriptions: {
        ...this.state.allDatesDescriptions,
        [name]: this.state.description,
      },
      allDatesClientQuery: {
        ...this.state.allDatesClientQuery,
        [name]: this.state.clientQuery,
      },
      descriptionDialog: false,
    });
  }

  // set totalDateHours empty
  // and toggle the arrow button
  changeState() {
    this.setState({
      totalDateHours: {},
    });
  }

  //handle month toggle for project filter dropdown
  async handleMonthToggle() {
    this.setState({is_submit_loading:true})
    console.log(" IN month Toggle")
    var tmsData = await this.fetchTimesheetDataPost()
    this.setState({ timesheetData: tmsData }, () => { 
      this.setData;
      this.changeState;
    })
    
    // this.projectFilterRef.current.monthToggleHandler(this.state.rows);
    // console.log(
    //   "inside Handle month toggle",
    //   this.state.rows,
    //   this.projectFilterRef.current
    // );
  }

  // render timesheet table if timesheet tab selected
  renderTimesheet(joining) {
    localStorage.setItem("page", "timesheet");
    var timesheet = (
      <div>
        <MonthToggle
          currDate={this.state.currDate}
          code={this.props.eid}
          setData={this.setData}
          changeState={this.changeState}
          onMonthToggle={this.handleMonthToggle}
        />
        <SingleTable
          ref={this.projectFilterRef}
          onMonthChange={this.monthToggleHandler}
          key={this.state.rejectedDates}
          joiningDate={joining}
          rejectedDates={this.state.rejectedDates}
          daysHeader={this.state.daysHeader}
          rows={this.state.rows}
          totalDateHours={this.state.totalDateHours}
          clientQueryDisabledDates={this.state.client_query_disabled_dates}
          onSubmitClientRes={this.submitClientResHandler}
        />
        <br />
        <br />
        <Footer
          applicable={this.state.applicable}
          clocked={this.state.clocked}
          leaves={this.state.leaves}
          holidays={this.state.holidays}
        />
        <br />
        <br />
        <Button
          onClick={this.handleSubmit}
          variant="contained"
          color="primary"
          style={{ align: "center" }}
        >
          Submit
        </Button>
      </div>
    );

    this.setState({
      currentTable: timesheet,
    });
  }

  // updateButton() {
  //   this.setState((prevState) => {
  //     is_active: !prevState.is_active;
  //   });
  // }
  
  // render team component if team management tab selected
  async renderApproverSheet() {
    this.setState({
      activeTab: {...this.state.activeTab, myTimesheet:false, teamManagement:true, backDatedTimesheet:false, projectInfo:false},
      is_loading: false,
    });
    var date = new Date();
    localStorage.setItem("date", format(date, "yyyy-MM-dd"));
    localStorage.setItem("page", "approver");

    var timesheet = (
      <div>
        <Team1
          fetchHolidays={this.fetchHolidays}
          countryCode={
            this.state.employee.holiday_calendar === ""
              ? 1
              : this.state.employee.holiday_calendar
          }
          // eid={this.props.eid}
          isStl={this.state.is_stl}
          isTech={this.state.is_tech}
          isFunc={this.state.is_func}
        />
      </div>
    );

    this.setState({
      currentTable: timesheet,
    });
  }

  // render Project info timesheet
  async projectinfotimesheet() { 
    // console.log("Project Info called");
    this.setState({
       activeTab: { ...this.state.activeTab, myTimesheet: false, teamManagement: false, backDatedTimesheet: false,projectInfo:true},
       is_loading: false })
    localStorage.setItem("page", "projectinfo");
    var date = new Date();
    localStorage.setItem("date", format(date, "yyyy-MM-dd"));

    let project = (<ProjectInfo />)
    this.setState({
      currentTable: project,
    });

  }


  // render backdated Timesheet
  async backdatedTimesheet() { 
    this.setState({ activeTab: { ...this.state.activeTab, myTimesheet: false, teamManagement: false, backDatedTimesheet: true, projectInfo: false}, is_loading: false })
    localStorage.setItem("page", "backdated");
    var date = new Date();
    localStorage.setItem("date", format(date, "yyyy-MM-dd"));

    let backTimesheet = (<BackdatedTimesheet />)
    this.setState({
      currentTable: backTimesheet,
    });
    
  }

  render() {
    // check for sso cookie, if not present redirect to login page
    var jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("C-X-ssoJwt="));
    if (jwt === undefined) {
      window.location = process.env.REACT_APP_SSO_LOGIN_URL;
    }

    const { classes } = this.props;
    var pending = this.state.pending;
    var dateString = "";
    for (let i = 0; i < pending.length; i++) dateString += `${pending[i]}, `;
    if (dateString.length > 0) {
      var len = dateString.length;
      dateString = dateString.slice(0, len - 2);
    }
    if (pending !== undefined) pending = pending.length;
    else pending = "-";

    return (
      <>
      <Marquee pauseOnHover={true} speed={30} direction="left" gradient={true}>
      <span className="blinking-text">{this.state.marQueeText}</span>
      </Marquee>
        {this.state.is_loading ? (
          <CircularProgress />
        ) : (
          <div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.submitAlert}
              autoHideDuration={5000}
              onClose={this.handleSubmitAlert}
            >
              <Alert onClose={this.handleSubmitAlert} severity="success">
                Changes Recorded
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.totalHourAlert}
              autoHideDuration={5000}
              onClose={this.handleHourAlert}
            >
              <Alert onClose={this.handleHourAlert} severity="warning">
                  Total hours should lie between 0-{this.state.configDataByName['Total Hours in Day']}
              </Alert>
            </Snackbar>
            
              {/* Leave Hour */}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.leaveHourAlert}
              autoHideDuration={5000}
              onClose={this.handleLeaveHourAlert}
            >
              <Alert onClose={this.handleLeaveHourAlert} severity="warning">
                Leave hours should lie between 0-8
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.totalHoliHourAlert}
              autoHideDuration={5000}
              onClose={this.handleHoliHourAlert}
            >
              <Alert onClose={this.handleHoliHourAlert} severity="warning">
                Total hours should be less than 16
              </Alert>
            </Snackbar>

            <div className={classes.root}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={2}>
                  <div className={classes.greet}>
                    Hi {this.state.employee.first_name} !
                  </div>
                </Grid>

                <Grid item xs={8}>
                  <ButtonGroup color="primary" size="large">
                    <Button
                      variant={this.state.activeTab.myTimesheet ? "contained" : "outlined"}
                      onClick={async () => {
                        //location.reload();
                        localStorage.setItem("page", "timesheet");
                        this.setState({
                          progress: 0,
                          //is_active: true,
                          activeTab: {...this.state.activeTab, myTimesheet:true, teamManagement:false, backDatedTimesheet:false, projectInfo:false},
                          is_loading: true,
                          totalDateHours:{}
                        });
                        await this.setData(this.state.currDate, this.props.eid);
                      }}
                    >
                      My Timesheet
                      </Button>

                      {/* Project Info */}
                    <Button variant={this.state.activeTab.projectInfo ? "contained" : "outlined"} onClick={this.projectinfotimesheet} >
                      Project Info</Button>
                
                    {(this.state.is_stl ||
                      this.state.is_func ||
                      this.state.is_tech) && (
                      <Button
                        variant={
                          this.state.activeTab.teamManagement ? "contained" : "outlined"
                        }
                        onClick={this.renderApproverSheet}
                      >
                        Team Management
                      </Button>
                    )}
                    
                    {(this.state.is_rm) && (
                      <Button
                        variant={
                          this.state.activeTab.backDatedTimesheet ? "contained" : "outlined"
                        }
                        onClick={this.backdatedTimesheet}
                      >
                        Backdated Timesheet
                      </Button>
                    )}
                  </ButtonGroup>
                </Grid>

                <Grid item xs={2}>
                  {localStorage.getItem("page") === "timesheet" && (
                    <div className={classes.pending}>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              Pending Dates
                            </Typography>
                            {dateString}
                          </React.Fragment>
                        }
                      >
                        <div>Pending Days: {pending}</div>
                      </Tooltip>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>

            {
              localStorage.getItem("page") === "timesheet" &&
              this.state.is_loading ? (
                <CircularProgress />
              ) : null
              //   <Box display="flex" alignItems="center">
              //     <Box width="100%" mr={1}>
              //       <BorderLinearProgress
              //         variant="determinate"
              //         value={this.state.progress}
              //       />
              //     </Box>
              //     <Box minWidth={40}>
              //       <Typography variant="body2" color="textSecondary">
              //         {`${Math.round(this.state.progress)}%`}
              //       </Typography>
              //     </Box>
              //   </Box>
            }

            <div id="timesheet">{this.state.currentTable}</div>

            {/* <Dialog
              open={this.state.fridayAlert}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth="sm"
            >
              <DialogTitle id="form-dialog-title">
                Value added this week
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  What value have added to the client this week?
                </DialogContentText>
                <TextField
                  multiline
                  rows={4}
                  variant="filled"
                  id="client-value"
                  name="client-value"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  onClick={this.handleFridaySubmit}
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog> */}

            <div id="dialog">
              <Dialog
                open={this.state.descriptionDialog}
                onClose={this.closeDialog}
                aria-labelledby="form-dialog-title1"
                fullWidth={true}
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title1">
                  {this.state.currSelectedDate}
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    variant="filled"
                    id="description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleDialogChange}
                    label="Description"
                    helperText={
                      this.state.invalidDescLength ? (
                        <span style={{ color: "red" }}>
                          Description limited to 255 characters
                        </span>
                      ) : (
                        ""
                      )
                    }
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="clientQuery"
                        id="clientQuery"
                        color="primary"
                        checked={this.state.clientQuery}
                        onChange={this.handleDialogChange}
                      />
                    }
                    label="Was this activity a part of client query?"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.invalidDescLength}
                    type="submit"
                    onClick={this.submitDialog}
                    color="primary"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>

              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.fillHourAlert}
                autoHideDuration={5000}
                onClose={this.handleFillHourAlert}
              >
                <Alert onClose={this.handleFillHourAlert} severity="warning">
                  Please fill hours first
                </Alert>
              </Snackbar>
            </div>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.noChange}
              autoHideDuration={5000}
              onClose={this.handleNoChange}
            >
              <Alert onClose={this.handleNoChange} severity="info">
                No changes done
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.clientValueSubmit}
              autoHideDuration={5000}
              onClose={this.handleFridaySubmitAlert}
            >
              <Alert onClose={this.handleFridaySubmitAlert} severity="success">
                Client value feedback recorded
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.clientValueAlert}
              autoHideDuration={5000}
              onClose={this.handleFridayValueAlert}
            >
              <Alert onClose={this.handleFridayValueAlert} severity="warning">
                Minimum length should be 20 characters
              </Alert>
            </Snackbar>
          </div>
        )}

        {/* On Submit Loading Backdrop */}
        <Backdrop
          className={classes.backdropStyle}
          open={this.state.is_submit_loading}
        >
          <CircularProgress
            color="primary"
            style={{
              marginRight: "50%",
              marginLeft: "40%",
              marginBottom: "20%",
            }}
          />
        </Backdrop>
      </>
    );
  }
}

export default withStyles(useStyles)(Timesheet);
