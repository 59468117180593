import React, { Component } from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import "./projectInfo.css";


const useStyles = (theme) => ({
  backdropStyle : {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    height: "120%",
    width: "120%",
    //position: "relative",
  }
})


class ProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: { display_name: '' },
      Data:[],
      projectDetails: null,
      loading: false,
      noRecordsFound: null
    };

    this.handleprojectinfo = this.handleprojectinfo.bind(this);
    this.handleProjectDetails = this.handleProjectDetails.bind(this);
  }

//   fetch the project names
  async projectTimesheet() {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/invoice-details-master/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },        
      }
    ).then((res) => res.json());
  }
   
//   fetch the project details
  async projectdetails(invoice_id) {
  return fetch(
    `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/project-details/${invoice_id}/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  ).then((res) => res.json());
}


  handleprojectinfo = async() => {
    this.setState({
      loading: true,
    });
    var option = await this.projectTimesheet()
    
    this.setState({
      Data: option,
      loading: false,
    });
  };

  handleProjectDetails = async(invoice_id) => {
    this.setState({
      loading: true,
    });

    var projectDetails = await this.projectdetails(invoice_id);

    projectDetails = ("detail" in projectDetails) ? null : projectDetails
    let noRecsFnd = (projectDetails == null & ("id" in this.state.selectedValue)) ? true : null

    this.setState({
      projectDetails,
      loading: false,
      noRecordsFound: noRecsFnd
    });
  }


  handleAutocompleteChange = (event, newValue) => {
    // console.log('Selected value:', newValue);
    this.setState({
      selectedValue: newValue, 
    });
  
    if (newValue) {
      const invoiceId = newValue.id; 
      // console.log('Invoice ID:', invoiceId);
      this.handleProjectDetails(invoiceId);
    } 
  };

  componentDidMount() { 
    this.handleprojectinfo();
  }

  render() {
    const { selectedValue, Data, projectDetails, noRecordsFound } = this.state;
    const getOptionLabel = (option) => option.display_name || '';
    const { classes } = this.props;

    return (
      <>
        <div className='project'>
          <Autocomplete
            value={selectedValue}
            options={Data}
            getOptionLabel={getOptionLabel}
            onChange={this.handleAutocompleteChange}
            noOptionsText={"No records found"}
            style={{ width: 400, margin: 20 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Projects"
                variant="filled"
              />
            )}
          />
        </div>
        <div>
        {/* {this.state.loading ? (
          <div className="backdrop">
            <div className="loader"></div>
          </div>
        ) : null} */}
        {noRecordsFound && <h2>No Records Found</h2>}
        {projectDetails && (
            <div>
            <h2>{projectDetails.project_name}</h2>
            </div>
            )}
        </div>
        <div className="table-container">
          {projectDetails && (
            <Table className="table">
              <TableBody>                
                <TableRow>
                  <TableCell className="header-cell"><strong>STL:</strong></TableCell>
                  <TableCell className="cell">{`${projectDetails.stl_name} - ${projectDetails.stl_emp_code}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header-cell"><strong>TECH LEAD:</strong></TableCell>
                  <TableCell className="cell">{`${projectDetails.tech_lead_name} - ${projectDetails.tech_emp_code}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header-cell"><strong>FUNCTIONAL LEAD:</strong></TableCell>
                  <TableCell className="cell">{`${projectDetails.func_lead_name} - ${projectDetails.func_emp_code}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header-cell"><strong>Start Date:</strong></TableCell>
                  <TableCell className="cell">{projectDetails.start_date}</TableCell>
                </TableRow>
                {/* <TableRow> */}
                  {/* <TableCell className="header-cell"><strong>End Date:</strong></TableCell> */}
                  {/* <TableCell className="cell">{projectDetails.end_date}</TableCell> */}
                {/* </TableRow> */}
                <TableRow>
                  <TableCell className="header-cell"><strong>Team Members ({ projectDetails.team_members.length }):</strong></TableCell>
                  <TableCell className="cell">
                    <div className="team-members-scroll">
                      <ul>
                        {projectDetails.team_members.map((member, index) => (
                          <li key={index}>{member}</li>
                        ))}
                      </ul>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
        {/* On Submit Loading Backdrop */}
        <Backdrop
          className={classes.backdropStyle}
          open={this.state.loading}
        >
          <CircularProgress
            color="primary"
            style={{
              marginRight: "50%",
              marginLeft: "40%",
              marginBottom: "20%",
            }}
          />
        </Backdrop>
      </>
    );
  }
}

export default withStyles(useStyles)(ProjectInfo);
