import React from "react";

import { Button, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const STLForm = (props) => {
  const [stl, setSTL] = React.useState({});
  const [tech, setTech] = React.useState({});
  const [functional, setFunctional] = React.useState({});
  const [recorded, setRecorded] = React.useState(false);
  const [somethingWrong, setSomethingWrong] = React.useState({status:false, message:""});

  // fetch all pre-filled values of STL tab dropdowns
  const fetchCurrRoles = () => {
    var url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/stl-view/${props.item.id}/`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (res) => {
      var data = await res.json();
      console.log(data);
      var stl = {
        id: data.stl_employee_code,
        display_name: data.stl_employee_name,
      };
      var tech = {
        id: data.tech_lead_emp_code,
        display_name: data.tech_lead_emp_name,
      };
      var func = {
        id: data.functional_lead_emp_code,
        display_name: data.functional_lead_emp_name,
      };

      console.log(stl, tech, func);

      setSTL(stl);
      setTech(tech);
      setFunctional(func);
    });
  };

  React.useEffect(() => {
    fetchCurrRoles();
  }, [props.item.id]);

  // change state values based on dropdown change
  const handleAutoComplete = (e, val, key) => {
    console.log(val);

    if (val === null) val = {};
    if (key == "stl") setSTL(val);
    else if (key == "tech") setTech(val);
    else setFunctional(val);
  };

  // send post request with changes to the backend
  async function sendRequest(obj) {
    return fetch(
      `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/master/stl-view/${props.item.id}/`,
      {
        // mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(obj),
      }
    ).then(res => res);
  }

  // gather and format changes it to send on save button click
  async function handleSubmit() {
    var obj = {
      stl_employee_code: stl.id,
      stl_employee_name: stl.display_name,
      tech_lead_emp_code: tech.id,
      tech_lead_emp_name: tech.display_name,
      functional_lead_emp_code: functional.id,
      functional_lead_emp_name: functional.display_name,
    };

    console.log(obj);

    let response = await sendRequest(obj);
    if (response.status == 200) {
      setRecorded(true)
    }
    else { 
      setSomethingWrong({
        ...somethingWrong,
        status: true,
        message: response["detail"],
      });
    }
  }

  // show recorded alert on save button click
  const handleAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setRecorded(false);
  };

  const handleSomeThingWrongAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setSomethingWrong({...somethingWrong, status:false, message:""})
  };

  return (
    <div>
      <h5>{props.item.display_name}</h5>
      <Autocomplete
        key="stl"
        disableClearable
        options={props.options["stl"]}
        getOptionLabel={(option) =>
          option.display_name === undefined
            ? ""
            : `${option.display_name} - ${option.id}`
        }
        style={{ width: 500, margin: 50, height: 0 }}
        value={stl}
        onChange={(e, val) => handleAutoComplete(e, val, "stl")}
        renderInput={(params) => (
          <TextField {...params} label="STL" variant="filled" />
        )}
      />
      <br />
      <Autocomplete
        key="tech"
        options={props.options["tech"]}
        getOptionLabel={(option) =>
          option.display_name === undefined
            ? ""
            : `${option.display_name} - ${option.id}`
        }
        style={{ width: 500, margin: 50, height: 0 }}
        value={tech}
        onChange={(e, val) => handleAutoComplete(e, val, "tech")}
        renderInput={(params) => (
          <TextField {...params} label="TECH LEAD" variant="filled" />
        )}
      />
      <br />
      <Autocomplete
        key="functional"
        options={props.options["functional"]}
        getOptionLabel={(option) =>
          option.display_name === undefined
            ? ""
            : `${option.display_name} - ${option.id}`
        }
        style={{ width: 500, margin: 50, height: 0 }}
        value={functional}
        onChange={(e, val) => handleAutoComplete(e, val, "functional")}
        renderInput={(params) => (
          <TextField {...params} label="FUNCTIONAL LEAD" variant="filled" />
        )}
      />

      <br />
      <br />
      <br />

      <Button color="primary" variant="contained" onClick={handleSubmit}>
        Save
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={recorded}
        autoHideDuration={5000}
        onClose={handleAlert}
      >
        <Alert onClose={handleAlert} severity="success">
          Recorded
        </Alert>
      </Snackbar>
        {/* Something Went Wrong */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={somethingWrong.status}
        autoHideDuration={5000}
        onClose={handleSomeThingWrongAlert}
      >
        <Alert onClose={handleSomeThingWrongAlert} severity="error">
          Something went wrong
        </Alert>
      </Snackbar>
    </div>
  );
};

export default STLForm;
