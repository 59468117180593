import React, { useState, useCallback, useEffect } from "react";
import { utils, write } from 'xlsx';
import { Button, TextField, Snackbar,ButtonGroup } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@mui/material/Chip";
import MaterialTable from "@material-table/core";
import Typography from "@mui/material/Typography";
import { useHistory as useHistoryHook } from "react-router-dom";

import "./ClickupData.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  input: {
    display: "none",
  },
  h2: {
    marginTop: 20,
    marginLeft: -100,
  },
  upload: {
    marginTop: 30,
    marginLeft: -20,
    marginBottom: 20,
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    width: "150px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 400,
    overflow: "auto",
  },
  tableCell: {
    padding: "4px 8px",
    lineHeight: "1.5",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflowing text
    textOverflow: "ellipsis", // Add ellipsis for overflowing text
  },
  errorRow: {
    backgroundColor: "#ffcccc",
  },
  createdRow: {
    backgroundColor: "rgba(0, 255, 0, 0.2)",
  },
  updatedRow: {
    backgroundColor: "#FFFFE0",
  },
});

const MarketingManagementUpload = ({ classes }) => {
  const history = useHistoryHook();
  const [departmentId, setDepartmentId] = useState(null);
  const [activeTab, setActiveTab] = useState("marketing_upload");
  const [loadingTables, setLoadingTables] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSecondTab, setShowSecondTab] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [tables, setTable] = useState([]);

  // Error Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Snackbar for no changes
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  // Success Snackbar state
  const [snackbarsucessOpen, setSnackbarsuccessOpen] = useState(false);
  const [snackbarsuccessMessage, setSnackbarsuccessMessage] = useState("");

  const [uploading, setUploading] = useState(false); // Loader for file upload
  const [loadSubmit, setloadSubmit] = useState(false); //Loader for file submit
  const [resData, setResData] = useState({
    num_rows_inserted: 0,
    num_rows_updated: 0,
  });

  // const handleViewTabdemandClick = () => {
  //   setActiveTab("demand_generation");
  //   history.push("/demand-generation");
  // };

  const handleUploadTabClick = () => {
    setActiveTab("marketing_upload");
  };

  const handleViewTabClick = () => {
    setActiveTab("marketing_view");
    history.push("/marketing-data");
  };

  //When table name is changed, fetch the columns
  useEffect(() => {
    const fetchTableColumns = async () => {
      try {
        // Mapping for table names
        const mapping = {
          Marketing_Digital_Marketing: "DigitalMarketing",
          Marketing_Demand_Generation: "MarketingDemandGeneration",
          Marketing_Brand_Awareness_EM: "EmailMarketing",
          Marketing_Brand_Awareness_PR: "MarketingBrandAwarenessPR",
          Marketing_Website_Analytics: "MarketingWebsiteAnalytics",
          Marketing_Event_Tracker: "MarketingEventTracker",
          Marketing_Content_Management: "MarketingContentManagement",
        };

        // Check if there is a new value and map it
        if (selectedTable) {
          const tableName = mapping[selectedTable.display_name] || "";
          // Fetch columns for the MUI Grid
          const response = await fetch(
            `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-get-columns/?table=${tableName}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          const responseData = await response.json();
          const modifiedColumns = responseData.columns.map((column) => {
            if (column === "error") {
              return column.toUpperCase(); // Convert 'error' to uppercase
            }
            return column;
          });
          setTableColumns(modifiedColumns);
        }
      } catch (error) {
        console.error("Error fetching table columns:", error);
      }
    };
    fetchTableColumns();
  }, [selectedTable]);

  const handleChangeTable = async (event, newValue) => {
    setSelectedTable(newValue);
    setTableData([]);
    setTableColumns([]);
    setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
  };

  const exportTemplateToExcel = async () => {
    if (!selectedTable) {
      setSnackbarText("Please select a table.");
      setSnackOpen(true);
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        table: selectedTable.display_name,
      }).toString();
      const url = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-get-columns?${queryParams}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('please select Table Name');
      }
  
      const jsonData = await response.json();

      if (jsonData.columns) {
        const filteredColumns = jsonData.columns.filter(column => column !== "error");
        const placeholderData = filteredColumns.reduce((acc, column) => {
          acc[column] = '';  
          return acc;
        }, {});
        
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([placeholderData], {header: filteredColumns});

        
        // Add the worksheet to the workbook
        utils.book_append_sheet(wb, ws, "Sheet1");
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        
        // Create download link
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${selectedTable.display_name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setSnackbarOpen(true);
      setSnackbarMessage(`Error exporting to Excel: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchDepartmentData();
  }, []);

  useEffect(() => {
    fetchTableNameData();
  }, []);

  const fetchDepartmentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/user-view`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();
      setDepartmentId(data.department_id);
      setShowSecondTab(data.department_id === 19);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };

  const fetchTableNameData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/marketing-fetch-tables`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      const transformedData = data.tables.map((table, index) => ({
        id: (index + 1).toString(),
        display_name: table,
      }));
      setTable(transformedData);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };

  const preprocessRows = (rows) => {
    return rows.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (
            typeof value === "string" &&
            ["nan", "none"].includes(value.toLowerCase())
          ) {
            return [key, ""];
          }
          return [key, value];
        })
      );
    });
  };

  const onSubmitFile = useCallback(
    async (event) => {
      const cleanedRows = preprocessRows(tableData);
      function fetchTimeout(url, options = {}, timeout = 900000) {
        // default to 15 minutes
        return new Promise((resolve, reject) => {
          // Create the timeout promise
          const timeoutId = setTimeout(() => {
            reject(new Error("Request timed out"));
          }, timeout);

          // Perform the fetch request
          fetch(url, options)
            .then((response) => {
              clearTimeout(timeoutId); // Clear the timeout if fetch completes
              resolve(response);
            })
            .catch((error) => {
              clearTimeout(timeoutId); // Clear the timeout in case of an error
              reject(error);
            });
        });
      }

      const tableApiMappings = {
        Marketing_Digital_Marketing: "digital-marketing-data-submit",
        Marketing_Demand_Generation: "demand-generation-data-submit",
        Marketing_Brand_Awareness_EM:
          "brand-awareness-email-marketing-submit",
        Marketing_Brand_Awareness_PR: "brand-awareness-pr-submit",
        Marketing_Website_Analytics: "website-analytics-data-submit",
        Marketing_Event_Tracker: "event-tracker-data-submit",
        Marketing_Content_Management: "content-management-data-submit",
      };
      const selectedTableName = selectedTable
        ? selectedTable?.display_name
        : "";
      const apiUrlPath = tableApiMappings[selectedTableName] || "";
      const apiUrl = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/${apiUrlPath}/`;

      const response = await fetchTimeout(
        apiUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ cleanedRows }),
        },
        900000
      );

      const result = await response.json();
      setResData(result);
      return result;
    },
    [tableData]
  );

  const handleSubmit = async () => {
    setloadSubmit(true);
    try {
      const result = await onSubmitFile();

      // Create maps for error, created, and updated rows
      const errorMap = new Map(
        result.errors.map((e) => [e.task_id, { ...e, status_task: "error" }])
      );
      const createdMap = new Map(
        result.results
          .filter((r) => r.status_task === "created")
          .map((r) => [r.task_id, { ...r, status_task: "created" }])
      );
      const updatedMap = new Map(
        result.results
          .filter((r) => r.status_task === "updated")
          .map((r) => [r.task_id, { ...r, status_task: "updated" }])
      );

      // Combine results from maps, prioritizing errors
      const combinedResults = [
        ...errorMap.values(),
        ...createdMap.values(),
        ...updatedMap.values(),
      ];

      // Update rows with combined results
      const updatedRows = tableData.map((row) => {
        const updatedRow = combinedResults.find(
          (res) => res.task_id === row.task_id
        );
        if (updatedRow) {
          return { ...tableData, ...updatedRow };
        }
        return row;
      });
      setTableData(updatedRows);

      if (!hasErrors() && result.errors.length == 0) {
        setSnackbarsuccessOpen(true);
        setSnackbarsuccessMessage(
          "Project details have been uploaded successfully."
        );
        setSubmitButtonDisabled(true);
      } else if (result.errors.length > 0) {
        // Show snackbar for errors
        setSnackOpen(true);
        setSnackbarText("No Changes Done");
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
    } finally {
      setloadSubmit(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleSuccessSnackbar = () => {
    setSnackbarsuccessOpen(false);
  };

  const onFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension !== "xlsx") {
          setSnackbarOpen(true);
          setSnackbarMessage(`${file.name} format is incorrect.`);
          return;
        }
        setUploading(true);
        setTableData([]);
        const formData = new FormData();
        formData.append("xlsx_file", file);
        const selectedTableName = selectedTable
          ? selectedTable?.display_name
          : "";

        const tableApiMappings = {
          Marketing_Digital_Marketing: "digital-marketing-data-upload",
          Marketing_Demand_Generation: "demand-generation-data-upload",
          Marketing_Brand_Awareness_EM:
            "brand-awareness-email-marketing-upload",
          Marketing_Brand_Awareness_PR: "brand-awareness-pr-upload",
          Marketing_Website_Analytics: "website-analytics-data-upload",
          Marketing_Event_Tracker: "event-tracker-data-upload",
          Marketing_Content_Management: "content-management-data-upload",
        };
        const apiUrlPath = tableApiMappings[selectedTableName] || "";
        const apiUrl = `${process.env.REACT_APP_TIMESHEET_DJANGO_URL}/dpaone/${apiUrlPath}/`;

        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const responseData = await response.json();

        let resData = [];
        let errorData = [];
        let errorFound = false;

        if (!responseData.status && responseData.message === "Error Occurred") {
          resData = responseData.data;
          errorFound = true;
          errorData.push("Incorrect column sequence or date format.");
        } else if (
          !responseData.status &&
          responseData.message === "Server Error"
        ) {
          errorFound = true;
          errorData.push("Something went wrong, Server Error");
        } else if (!responseData.status || responseData.status == 400) {
          errorFound = true;
          errorData.push(responseData.message);
        } else {
          resData = responseData && responseData.data ? responseData.data : [];
        }

        if (errorFound) {
          setSnackbarOpen(true);
          setSnackbarMessage(errorData);
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        } else {
          setSnackbarsuccessOpen(true);
          setSnackbarsuccessMessage("Kindly review and Submit the Data.");
          setResData({ num_rows_inserted: 0, num_rows_updated: 0 });
        }
        setTableData(resData);
        setUploading(false);
        setSubmitButtonDisabled(false);
      }
    },
    [tableData]
  );

  const hasErrors = () => {
    return tableData.some((row) => row.error && row.error.trim() !== "");
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "calibri",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "black",
          textAlign: "center",
          marginTop: "2px",
          margin: "8px 0",
          marginBottom: "2px",
          padding: "5px",
        }}
      >
        Marketing Data Upload
      </Typography>
      <div className={classes.tabs} style={{ marginBottom: 20 }}>
        <div
          className={classes.tabs}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
         <ButtonGroup
          color="primary"
          aria-label="small outlined button group"
          size="large"
        >
        <Button
          // style={{ padding: "5px 10px" }}
          variant={activeTab === "marketing_view" ? "contained" : "outlined"}
          color="primary"
          onClick={handleViewTabClick}
        >
          View Data
         </Button>
        <Button
          // style={{ padding: "5px 10px" }}
          variant={activeTab === "marketing_upload" ? "contained" : "outlined"}
          color="primary"
          onClick={handleUploadTabClick}
        >
          Upload Data
        </Button>
        </ButtonGroup>
          {/* <Button
              style={{ padding: "5px 10px" }}
              variant={activeTab === "demand_generation" ? "contained" : "outlined"}
              color="primary"
              onClick={handleViewTabdemandClick}
            >
              Demand Generation
            </Button> */}
        </div>
      </div>

      {isLoading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      {uploading ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <div className="project-details">
        <Autocomplete
          options={tables}
          style={{
            width: 400,
            marginLeft: 400,
            marginTop: 15,
            alignContent: "center",
          }}
          noOptionsText={"No records found"}
          getOptionLabel={(option) => option.display_name}
          value={selectedTable}
          onChange={handleChangeTable}
          loading={loadingTables}
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="Select Table" />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.display_name}
                {...getTagProps({ index })}
                key={option.id} 
                style={{ marginRight: 4, marginBottom: 4 }} 
              />
            ))
          }
          sx={{
            width: 600,
            marginLeft: 400,
            marginTop: 30,
            "& .MuiAutocomplete-tag": {
              marginRight: 4,
              marginBottom: 4,
            },
            "& .MuiAutocomplete-paper": {
              maxHeight: 200, 
              overflowY: "auto",
            },
          }}
        />
      </div>

      <div
        className={classes.upload}
        style={{ alignItems: "center", gap: "10px" }}
      >
        <input
          accept=".xlsx"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={(event) => {
            onFileUpload(event);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
          style={{ display: "none" }}
          disabled={!selectedTable}
        />
        <label htmlFor="contained-button-file">
          <Button
            startIcon={<PublishIcon />}
            variant="contained"
            color="primary"
            component="span"
            style={{ marginRight: "10px", height: "32px" }}
            disabled={!selectedTable}
          >
            Upload
          </Button>
        </label>
        <Button
          style={{ padding: "5px 10px", height: "32px" }}
          variant={activeTab === "marketing_upload" ? "contained" : "outlined"}
          color="primary"
          onClick={exportTemplateToExcel}
        >
          Template
        </Button>
      </div>

      {/* Snackbar for errors */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <MuiAlert onClose={handleCloseSnack} severity="info" variant="filled">
          {snackbarText}
        </MuiAlert>
      </Snackbar>

      <MaterialTable
        options={{
          filtering: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 50, 100, 250],
          headerStyle: {
            backgroundColor: "#2474FC",
            color: "#FFFFFF",
          },
          rowStyle: {
            backgroundColor: "#FFFFFF",
          },
          rowStyle: (rowData) => {
            if (rowData.status_task === "error" || rowData.error) {
              return { backgroundColor: "#ffcccc" }; // Error row style
            } else if (rowData.status_task === "created") {
              return { backgroundColor: "rgba(0, 255, 0, 0.2)" }; // Created row style
            } else if (rowData.status_task === "updated") {
              return { backgroundColor: "#FFFFE0" }; // Updated row style
            } else {
              return { backgroundColor: "#FFFFFF" }; // Default row style
            }
          },
          maxBodyHeight: 800, // Adjust this height as needed
          overflowX: "auto", // Enable horizontal scrolling
        }}
        style={{
          overflowX: "auto", // Enable horizontal scrolling for the entire table
        }}
        
        title="Data to Upload"
        columns={tableColumns.map((column) => ({
          title: column,
          field: column,
          cellStyle:
            column === "ERROR"
              ? {
                whiteSpace: 'break-spaces', // Ensure single-line text
                overflowX: 'hidden', // Enable horizontal scrolling within the cell
                //textOverflow: 'ellipsis', // Add ellipsis if text overflows
                minWidth: '400px', // Set a reasonable minimum width
                maxWidth: '600px', // Allow the column to grow as needed
                }
              : 
              column === "ABOUT_INQUIRY" 
              ? { 
                  width: '800px', 
                  maxWidth: '800px', 
                  minWidth: '500px', 
                  whiteSpace: 'normal',  
                  wordWrap: 'break-word'
                }
            : 
            ["TITLE", "LINK"].includes(column) 
        ? {
            width: '400px', 
            maxWidth: '600px',
            minWidth: '350px',
            whiteSpace: 'normal',  
            wordWrap: 'break-word'
          }:
          {},
          headerStyle: column === "ERROR" ? 
            {
              whiteSpace: 'break-spaces', 
              overflowX: 'hidden', 
              minWidth: '400px', 
              maxWidth: '600px', 
          } :
          column === "ABOUT_INQUIRY" 
          ? { width: '800px', maxWidth: '800px', minWidth: '500px' } 
          : 
          ["TITLE", "LINK"].includes(column) 
        ? {
            width: '400px',
            maxWidth: '600px',
            minWidth: '350px',
          }:
          {},
          render: (rowData) => {
            // Check if the column is "ERROR" and map it to the lowercase "error" field in the data
            if (column === "ERROR") {
              return rowData.error ? rowData.error.toString(): "";
            }
            return rowData[column]; // Default for other columns
          },
        }))}
        data={tableData}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <span className="boldText">
          Rows Created: {resData["num_rows_inserted"]}
        </span>
        <span className="boldText">
          Rows Updated: {resData["num_rows_updated"]}
        </span>
      </div>
      {loadSubmit ? (
        <div className="backdrop">
          <div className="loader"></div>
        </div>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarsucessOpen}
        autoHideDuration={5000}
        onClose={handleSuccessSnackbar}
      >
        <MuiAlert
          onClose={handleSuccessSnackbar}
          severity="success"
          variant="filled"
        >
          {snackbarsuccessMessage}
        </MuiAlert>
      </Snackbar>

      {/* Submit Button */}
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={tableData.length === 0 || hasErrors() || isSubmitButtonDisabled}
        style={{
          align: "center",
          marginTop: 10,
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default withStyles(styles)(MarketingManagementUpload);
