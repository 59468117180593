import React from "react";
import { Grid, Typography } from "@material-ui/core";

class Footer extends React.Component {
  render() {
    const applicable = this.props.applicable;
    const clocked = this.props.clocked;
    const leaves = this.props.leaves;
    const holidays = this.props.holidays;

    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs>
            <Typography>Applicable Hours: {applicable}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>Clocked Hours: {clocked.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>Leave Hours: {leaves}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>Holiday Hours: {holidays}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>
              Remaining Hours: {(applicable - (clocked + leaves)).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Footer;
